import { CakeIcon, Container, Description } from './styles'
import CakePng from '../../assets/images/cake.png'
import { i18n } from '../../translate/i18n'

const BirthdayNotFound: React.FC = () => (
    <Container>
        <CakeIcon src={CakePng} />
        <Description>{i18n.t('birthdays.notFound')}</Description>
    </Container>
)

export default BirthdayNotFound
