import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps, useLocation } from 'react-router';
import { Dispatch } from 'redux';
import Loader from '../components/Loader';
import { ApplicationState } from '../store';
import AppThunk from '../store/containers/app/thunk'

interface StateProps {
    loading: boolean,
    error: boolean,
    errormessage: string
}

interface DispatchProps {
    loadToken(hash: string): void
}

type Props = StateProps & DispatchProps & RouteProps

const AuthRoute: React.FC<Props> = ({ loading, error, errormessage, loadToken, ...routeProps }: Props) => {
    const { hash } = useLocation()

    useEffect(() => {
        loadToken(hash.split("#")[1])
    }, [])

    return (
        <Loader loading={loading} error={error} errorMessage={errormessage}>
            <Route {...routeProps} />
        </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.app.loading,
    error: state.app.error,
    errormessage: state.app.errormessage
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadToken: (hash: string) => dispatch(AppThunk.loadToken(hash) as any)
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute)