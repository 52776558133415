import { action } from "typesafe-actions";
import { ClassifiedTypes } from "./types";

export const loadSetup = (data: any) => action(ClassifiedTypes.LOAD_CLASSIFIED_SETUP, { data })

export const loadClassifiedRequest = () => action(ClassifiedTypes.LOAD_CLASSIFIED_REQUEST)
export const loadClassifiedSetup = (data: any) => action(ClassifiedTypes.LOAD_CLASSIFIED_SETUP, { data })
export const loadClassifiedProducts = (data: any) => action(ClassifiedTypes.LOAD_CLASSIFIED_PRODUCTS, { data })
export const loadClassifiedServices = (data: any) => action(ClassifiedTypes.LOAD_CLASSIFIED_SERVICES, { data })
export const loadClassifiedMyProducts = (data: any) => action(ClassifiedTypes.LOAD_CLASSIFIED_MY_PRODUCTS, { data })

export const loadAnnouncement = (data: any) => action(ClassifiedTypes.LOAD_ANNOUNCEMENT, { data })
export const unloadAnnouncement = () => action(ClassifiedTypes.UNLOAD_ANNOUNCEMENT)

export const loadClassifiedFailure = (error: string) => action(ClassifiedTypes.LOAD_CLASSIFIED_FAILURE, error)

export const loadSellerData = (data: any) => action(ClassifiedTypes.LOAD_SELLER_DATA, { data })

export const loadClassifiedCategories = (data: any) => action(ClassifiedTypes.LOAD_CLASSIFIED_CATEGORIES, { data })
export const loadClassifiedTerms = (data: any) => action(ClassifiedTypes.LOAD_CLASSIFIED_TERMS, { data })
export const loadAnnouncementTypes = (data: any) => action(ClassifiedTypes.LOAD_CLASSIFIED_ANNOUNCEMENT_TYPES, { data })

export const setTerms = (data: any) => action(ClassifiedTypes.SET_CLASSIFIED_TERMS, { data })

