export const TOKEN_KEY = "@services_token"
export const TOKEN_REFRESH = "@services_token_refresh"
export const TOKEN_EXPIRES_IN = "@services_token_expires_in"

export const API_SERVICE = "@api_services"

export const isAuthenticated = (): boolean => Boolean(localStorage.getItem(TOKEN_KEY));

export const tokenToUpload = localStorage.getItem(TOKEN_KEY)

export const asyncLocalStorage = {
    setItem: async (key: string, value: string) => {
        await null;
        return localStorage.setItem(key, value);
    },
    getItem: async (key: string) => {
        await null;
        return localStorage.getItem(key);
    },
    removeItem: async (key: string) => {
        await null;
        return localStorage.removeItem(key);
    }
};