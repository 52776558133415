import { combineReducers } from 'redux'
import trainings from './training'
import classifieds from './classified'
import terms from './terms'
import user from './user'
import app from './app'
import gifts from './gifts'
import documents from './documents'
import birthdays from './birthdays'
import birthwork from './birthwork'
import settings from './settings'

const rootReducer = combineReducers({
    app,
    terms,
    trainings,
    classifieds,
    documents,
    gifts,
    user,
    birthdays,
    birthwork,
    settings,
})

export default rootReducer
