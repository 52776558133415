import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'

import { createHashHistory } from 'history'

import PrivateRoute from './PrivateRoute'

import Training from '../pages/Training'
import TrainingDetail from '../pages/Training/show'
import TrainingList from '../pages/Training/list'
import Terms from '../pages/Terms'
import TermsList from '../pages/Terms/list'
import TermDetail from '../pages/Terms/show'
import Classified from '../pages/Classified'
import ClassifiedDetail from '../pages/Classified/show'
import ClassifiedCreate from '../pages/Classified/create/index'
import ErrorRoute from './ErrorRoute'
import FolderList from '../pages/Documents/list/folderList'
import FileList from '../pages/Documents/list/fileList'
import Documents from '../pages/Documents'
import GiftQueue from '../pages/Gifts/queue'
import Gifts from '../pages/Gifts'
import Birthdays from '../pages/Birthdays'
import BirthdaysListScreen from '../pages/Birthdays/list'
import SettingsScreen from '../pages/Settings/Screen'
import Birthwork from '../pages/Birthwork'
import BirthworkList from '../pages/Birthwork/list'
import Settings from '../pages/Settings'
// import HubPage from '../pages/Hub'

export const history = createHashHistory({
    hashType: 'slash',
    getUserConfirmation: (message, callback) =>
        callback(window.confirm(message)),
})

const Routes = () => (
    <Router history={history}>
        <Switch>
            <PrivateRoute exact path='/training' component={Training} />
            <PrivateRoute
                exact
                path='/training/list'
                component={TrainingList}
            />
            <PrivateRoute
                exact
                path='/training/show/:id'
                component={TrainingDetail}
            />

            <PrivateRoute exact path='/terms' component={Terms} />
            <PrivateRoute exact path='/terms/list' component={TermsList} />
            <PrivateRoute exact path='/terms/show/:id' component={TermDetail} />

            <PrivateRoute exact path='/classified' component={Classified} />
            <PrivateRoute
                exact
                path='/classified/show/:id'
                component={ClassifiedDetail}
            />
            <PrivateRoute
                exact
                path='/classified/create'
                component={ClassifiedCreate}
            />
            <PrivateRoute
                exact
                path='/classified/create/:id'
                component={ClassifiedCreate}
            />

            <PrivateRoute exact path='/docsrh' component={Documents} />

            <PrivateRoute exact path='/docsrh/list' component={FolderList} />
            <PrivateRoute
                exact
                path='/docsrh/folder/:id'
                component={FileList}
            />

            <PrivateRoute exact path='/brindes/' component={Gifts} />
            <PrivateRoute exact path='/brindes/queue' component={GiftQueue} />

            <PrivateRoute exact path='/birthlife/' component={Birthdays} />
            <PrivateRoute
                exact
                path='/birthlife/list'
                component={BirthdaysListScreen}
            />

            <PrivateRoute exact path='/birthwork' component={Birthwork} />
            <PrivateRoute
                exact
                path='/birthwork/list'
                component={BirthworkList}
            />

            <PrivateRoute exact path='/settings' component={Settings} />
            <PrivateRoute
                exact
                path='/settings/screen'
                component={SettingsScreen}
            />

            {/* <Route exact path='/' component={HubPage} /> */}

            <Route exact path='/error' component={ErrorRoute} />
        </Switch>
    </Router>
)

export default Routes
