import styled from 'styled-components';

export const Container = styled.div`
    padding: 10px 20px;
    background-color: ${props => props.theme.colors.primary};

    input {
        width: 100%;
        padding: 10px 20px;

        font-size: 18px;
        line-height: 18px;

        border: none;
        outline: none;
        border-radius: 10px;
    }
`;