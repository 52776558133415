import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Dispatch } from 'redux';
import { useConfirmation } from '../../../components/Modal/Confirmation/confirmation-service';
import { ApplicationState } from '../../../store';
import GiftsThunk from '../../../store/containers/gifts/thunk';
import { IGift, IGiftSetup } from '../../../store/containers/gifts/types';
import Loader from '../../../components/Loader';
import PinScreen from './pin-screen';
import QueueScreen from './queue-screen';
import MiniLoader from '../../../components/MiniLoader';

import { TopBar } from './style';

interface StateProps {
    gifts: IGift
    giftSetup: IGiftSetup
    loading: boolean
    error: boolean
    errorMessage: string
    receivedLoading: boolean
    receivedError: boolean
    receivedErrorMessage: string
    pin: string
}

interface DispatchProps {
    loadGifts: () => void
    setReceived: (history: any, pin?: string, setPinScreen?: any) => void
}

type Props = StateProps & DispatchProps

const GiftQueue: React.FC<Props> = (props: Props) => {

    const confirm = useConfirmation()
    const history = useHistory()

    const [pinScreen, setPinScreen] = useState<boolean>(false)
    const [pinValue, setPinValue] = useState<string>("")

    useEffect(() => {
        const { loadGifts } = props
        loadGifts()
    }, [])

    const { pin } = props

    useEffect(() => {
        if (pin === "need_pin" && !pinScreen) {
            setPinScreen(true)
        }
    }, [pin])

    const RequestGift = () => {
        const { setReceived } = props
        if (pin === "need_pin" && !pinScreen) {
            setPinScreen(true)
        }

        if (pin === "need_pin" && pinValue.length === 4) {
            setReceived(history, pinValue, setPinScreen)
        }

        if (pin === "") {
            setReceived(history)
        }
    }

    const { receivedError, receivedErrorMessage, receivedLoading } = props

    useEffect(() => {
        if (receivedError && receivedErrorMessage) {
            confirm({
                variant: "info",
                description: receivedErrorMessage
            })
        }
    }, [receivedError])

    const { loading, error, errorMessage, gifts, giftSetup } = props

    return (
        <Loader loading={loading} error={error} errorMessage={errorMessage}>
            <TopBar />
            {
                pinScreen ?
                    <PinScreen pinValue={pinValue} setPinScreen={setPinScreen} setPinValue={setPinValue} RequestGift={RequestGift} />
                    :
                    <QueueScreen gifts={gifts} RequestGift={RequestGift} giftSetup={giftSetup} />
            }
            <MiniLoader loading={receivedLoading} />
        </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    gifts: state.gifts.gifts,
    giftSetup: state.gifts.giftSetup,
    pin: state.gifts.pin,
    loading: state.gifts.loading,
    error: state.gifts.error,
    errorMessage: state.gifts.errorMessage,
    receivedLoading: state.gifts.receivedLoading,
    receivedError: state.gifts.receivedError,
    receivedErrorMessage: state.gifts.receivedErrorMessage,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadGifts: () => dispatch(GiftsThunk.loadGifts() as any),
    setReceived: (history: any, pin?: string, setPinScreen?: any) => dispatch(GiftsThunk.setReceived(history, pin, setPinScreen) as any)
})

export default connect(mapStateToProps, mapDispatchToProps)(GiftQueue);