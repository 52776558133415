import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { Dispatch } from "redux";
import Loader from "../../components/Loader";
import { ApplicationState } from "../../store";
import BirthdaysThunk from "../../store/containers/birthdays/thunk";
import { BirthdaysTypes } from "../../store/containers/birthdays/types";

interface StateProps {
  loading: boolean;
  error: boolean;
}

interface DispatchProps {
  loadSetup: (hash: string) => void;
}

type Props = DispatchProps & StateProps;

const Birthdays: React.FC<Props> = (props: Props) => {
  const { loading, error } = props;
  const { hash } = useLocation();

  useEffect(() => {
    const { loadSetup } = props;
    loadSetup(hash);
  }, []);

  return (
    <Loader loading={loading} error={error}>
      <Redirect to={{ pathname: "/birthlife/list" }} />
    </Loader>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.birthdays.loading,
  error: state.birthdays.error,
  theme: state.app.theme,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadRequest: () => dispatch(BirthdaysTypes.LOAD_BIRTHDAYS_REQUEST as any),
  loadSetup: (hash: string) => dispatch(BirthdaysThunk.loadSetup(hash) as any),
});

export default connect(mapStateToProps, mapDispatchToProps)(Birthdays);
