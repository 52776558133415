import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { asyncLocalStorage } from '../../../auth/auth'
import { api } from '../../../services/api'
import simplifica from '../../../styles/themes/simplifica'
import * as BirthdaysActions from './actions'

const BirthdaysThunk = {
    loadSetup: (hash: string) => (dispatch: Dispatch) => {
        dispatch(BirthdaysActions.loadBirthdaysRequest())
        api.post('/aniversariantesvida/setup', { hash })
            .then((response: AxiosResponse) => {
                if (response.data?.state) {
                    BirthdaysThunk.loadTheme(response.data.data)
                    asyncLocalStorage
                        .setItem(
                            '@services_header',
                            JSON.parse(response.data.data)[0].EsconderHeader
                        )
                        .then(() =>
                            dispatch(
                                BirthdaysActions.loadBirthdaysSetup(
                                    JSON.parse(response.data.data)[0]
                                )
                            )
                        )
                } else {
                    dispatch(
                        BirthdaysActions.loadBirthdaysFailure(
                            'SETUP - Erro no Carregamento'
                        )
                    )
                }
            })
            .catch((error: any) => {
                Sentry.captureException('SETUP - Erro no Carregamento')
                dispatch(
                    BirthdaysActions.loadBirthdaysFailure(
                        `SETUP - ${error.message}`
                    )
                )
            })
    },
    // TODO: Refactor to abstract the theme loading
    loadTheme: (data: any) => {
        const { TituloModulo, CorPrimaria, CorSecundaria } = JSON.parse(data)[0]
        asyncLocalStorage
            .setItem('@services_page_title', TituloModulo)
            .then(() => {
                simplifica.colors.primary = CorPrimaria
                simplifica.colors.secondary = CorSecundaria

                simplifica.trainingButtons[0].color = CorPrimaria
                simplifica.trainingButtons[1].color = CorPrimaria

                simplifica.classifiedCategories.tipoUso[0].background =
                    CorSecundaria
            })
    },

    loadBirthdays: () => (dispatch: Dispatch) => {
        dispatch(BirthdaysActions.loadBirthdaysRequest())
        api.get('/aniversariantesvida/carregar').then(
            (response: AxiosResponse) => {
                if (response.data?.state) {
                    dispatch(
                        BirthdaysActions.loadBirthdaysSuccess(
                            JSON.parse(response.data.data)
                        )
                    )
                } else {
                    dispatch(
                        BirthdaysActions.loadBirthdaysFailure(
                            'Erro no Carregamento'
                        )
                    )
                }
            }
        )
    },
}

export default BirthdaysThunk
