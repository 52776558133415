import React from 'react';

import { Container, DarkScreen, LoadingModal, Spinning, Text } from './style';
import loadingGIF from '../../assets/images/loading.gif'

type Props = {
    loading: boolean
}

const MiniLoader: React.FC<Props> = ({ loading }: Props) => (
    <Container>
        {loading &&
            <>
                <DarkScreen />
                <LoadingModal>
                    <Spinning src={loadingGIF} />
                    <Text>Carregando</Text>
                </LoadingModal>
            </>
        }
    </Container>
)

export default MiniLoader;