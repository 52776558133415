import { useEffect, useRef, useState } from 'react'

const useDebounce = (callback: any, delay: number) => {
    const timeoutRef = useRef<any>()

    useEffect(() => {
        if (timeoutRef.current) {
            return () => clearTimeout(timeoutRef.current)
        }
    }, [])

    const debouncedCallback = (...args: any) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(() => {
            callback(...args)
        }, delay)
    }
    return debouncedCallback
}

const useDebouncedValue = (inputValue: any, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(inputValue);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(inputValue);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }, [inputValue, delay]);
  
    return debouncedValue;
  };


export {useDebounce, useDebouncedValue}
