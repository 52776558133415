import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { ApplicationState } from '../../../store'
import BirthdaysThunk from '../../../store/containers/birthdays/thunk'

import BirthdayCard from '../../../components/BirthdayCard'
import Tabs from '../../../components/BirthdayTabs'
import Breadcrumb from '../../../components/Breadcrumb'
import {
    IBirthday,
    IBirthdaySetup,
} from '../../../store/containers/birthdays/types'
import { Container } from './styles'

interface StateProps {
    loading: boolean
    birthdays: Array<IBirthday>
    setup: IBirthdaySetup
}

interface DispatchProps {
    loadBirthdays: () => void
}

type Props = StateProps & DispatchProps

const BirthdaysListScreen: React.FC<Props> = (props: Props) => {
    const { loading, birthdays, setup } = props

    useEffect(() => {
        const { loadBirthdays } = props
        loadBirthdays()
    }, [])

    return (
        <Container>
            <Breadcrumb show={true} />
            <BirthdayCard />
            <Tabs
                birthdays={birthdays}
                type='birthdays'
                hasHeader={!setup.EsconderHeader}
            />
        </Container>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.birthdays.loading,
    error: state.birthdays.error,
    errorMessage: state.birthdays.errorMessage,
    birthdays: state.birthdays.birthdays,
    setup: state.birthdays.setup,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadBirthdays: () => dispatch(BirthdaysThunk.loadBirthdays() as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(BirthdaysListScreen)
