import { action } from 'typesafe-actions'
import { BirthdaysTypes, IBirthday, IBirthdaySetup } from './types'

export const loadBirthdaysSetup = (data: IBirthdaySetup) =>
    action(BirthdaysTypes.LOAD_BIRTHDAYS_SETUP, { data })

export const loadBirthdaysRequest = () =>
    action(BirthdaysTypes.LOAD_BIRTHDAYS_REQUEST)
export const loadBirthdaysSuccess = (data: IBirthday[]) =>
    action(BirthdaysTypes.LOAD_BIRTHDAYS_SUCCESS, { data })
export const loadBirthdaysFailure = (error: any) =>
    action(BirthdaysTypes.LOAD_BIRTHDAYS_FAILURE, error)
