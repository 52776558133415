import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ClassifiedThunk from '../../../store/containers/classified/thunk';
import Breadcrumb from '../../../components/Breadcrumb';
import Tabs from '../../../components/Tabs';
import Tab from '../../../components/Tabs/Tab';
import Products from './products';
import Services from './services';
import MyAnnouncements from './my-announcements';
import ThatsAll from '../../../components/ThatsAll';
import { ApplicationState } from '../../../store';
import Loader from '../../../components/Loader';
import { IClassifiedSetup } from '../../../store/containers/classified/types';

import { Container } from './style';

interface StateProps {
    loading: boolean;
    error: boolean;
    typeAnnouncements: IClassifiedSetup
}

interface DispatchProps {
    loadAnnouncements(): void
}

type Props = StateProps & DispatchProps

const ClassifiedList: React.FC<Props> = (props: Props) => {

    const { typeAnnouncements, loading, error } = props


    useEffect(() => {
        const { loadAnnouncements } = props
        loadAnnouncements();
    }, [])

    const validType = useCallback((id: number): boolean =>
        typeAnnouncements.PermitirTipos &&
        Boolean(typeAnnouncements.PermitirTipos.find(type => type.id === id))
        , [typeAnnouncements])

    return (
        <>
            <Loader loading={loading} error={error}>
                <Container>
                    <Breadcrumb show={false}><b>Classificados</b></Breadcrumb>

                    <Tabs initialWidth={74} initialPosition={25}>

                        <Tab title='Produtos' show={validType(0)}>
                            <Products />
                            <ThatsAll>Você visualizou todos classificados disponíveis</ThatsAll>
                        </Tab>

                        <Tab title='Serviços' show={validType(1)}>
                            <Services />
                            <ThatsAll>Você visualizou todos classificados disponíveis</ThatsAll>
                        </Tab>

                        <Tab title="Meus Produtos" show>
                            <MyAnnouncements />
                        </Tab>
                    </Tabs>
                </Container>
            </Loader>
        </>
    );

}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.classifieds.loading,
    error: state.classifieds.error,
    typeAnnouncements: state.classifieds.setup
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadAnnouncements: () => dispatch(ClassifiedThunk.loadAnnouncements() as any)
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassifiedList);