import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    padding: 0 23px;
    margin-top: 30px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 15px;
  background-color: ${props => props.theme.colors.primary};
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const Image = styled.img`
    height: 64px;
    width: 64px;
    border: 2px solid white;
    border-radius: 50%;
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0,.12));
`

export const HeaderContainer = styled.div`
    margin-left: 10px;
`

export const Title = styled.h1`
    font-size: 24px;
    font-weight: 700;
`

export const Department = styled.p`
    font-size: 15px;
    font-weight: 400;
`

export const Content = styled.div`
    padding: 30px 0 100px;
`

export const Button = styled.button<{ primary?: boolean }>`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 100%;
    max-width: 350px;
    margin-top: 10px;
    padding: 20px;
    border: none;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 700;

    background-color: ${props => props.primary ? props.theme.colors.primary : "white"};
    color: ${props => props.primary ? "white" : props.theme.colors.text};
    margin-bottom: 15px;
`