/**
 * Action Types
 */

// eslint-disable-next-line no-shadow
export enum TrainingTypes {
    LOAD_TRAINING_SETUP = "@training/LOAD_TRAINING_SETUP",

    LOAD_TRAINING_REQUEST = "@training/LOAD_TRAINING_REQUEST",
    LOAD_TRAINING_SUCCESS = "@training/LOAD_TRAINING_SUCCESS",
    LOAD_TRAINING_FAILURE = "@training/LOAD_TRAINING_FAILURE",

    LOAD_TRAINING_PROGRESS = "@training/LOAD_TRAINING_PROGRESS",

    LOAD_SINGLE_TRAINING_SUCCESS = "@training/LOAD_SINGLE_TRAINING_SUCCESS",

    COMPLETE_TRAINING_REQUEST = "@training/COMPLETE_TRAINING_REQUEST",
    COMPLETE_TRAINING_SUCCESS = "@training/COMPLETE_TRAINING_SUCCESS",
    COMPLETE_TRAINING_FAILURE = "@training/COMPLETE_TRAINING_FAILURE"
}

/** 
 * Data Types
*/

export interface ITraining {
    codigo: number,
    titulo: string,
    subtitulo: string,
    status: number,
    imagem: string,
    conteudo: string
}

export interface ITrainingProgress {
    actualProgress: number,
    finalProgress: number
}

/**
 * State Types
 */

export interface TrainingState {
    readonly trainings: ITraining[]
    readonly activeTraining: ITraining
    readonly trainingProgress: ITrainingProgress
    readonly loading: boolean
    readonly error: boolean
    readonly errorMessage: string
}