import React from 'react'
import { connect } from 'react-redux'
import Gift from '../../assets/images/birthday-gift.svg'
import { ApplicationState } from '../../store'
import { Container, Description, TextWrapper, Title } from './styles'
import { IBirthdaySetup } from '../../store/containers/birthdays/types'

interface StateProps {
    setup: IBirthdaySetup
}

type Props = StateProps

const BirthdayCard: React.FC<Props> = ({ setup }) => {
    if (!setup.CardTitulo || !setup.CardDescricao) return null
    return (
        <Container>
            <img src={Gift} alt='' className='icon' />
            <TextWrapper>
                <Title>{setup.CardTitulo}</Title>
                <Description>{setup.CardDescricao}</Description>
            </TextWrapper>
        </Container>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    setup: state.birthdays.setup,
})

export default connect(mapStateToProps)(BirthdayCard)
