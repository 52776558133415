import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import Breadcrumb from '../../../components/Breadcrumb'
import Loader from '../../../components/Loader'
import { ApplicationState } from '../../../store'
import { setSettingsState } from '../../../store/containers/settings/actions'
import SettingsThunk from '../../../store/containers/settings/thunk'
import {
    ISettings,
    ISettingsOptions,
    ISettingsSetup,
    TSettingsField,
} from '../../../store/containers/settings/types'
import { i18n } from '../../../translate/i18n'
import Accessibility from '../Accessibility'
import Notifications from '../Notifications'
import Profile from '../Profile'
import Security from '../Security'
import { Button, Container, SettingsContainer } from './styles'

interface StateProps {
    updateLoading: boolean
    error: boolean
    errorMessage: string
    settings: ISettings
    setup: ISettingsSetup
    options: ISettingsOptions
}

interface DispatchProps {
    loadSettings: () => void
    updateSettings: (settings: ISettings) => void
    setSettingsState: (field: TSettingsField, value: any) => void
}

type Props = StateProps & DispatchProps

const SettingsScreen: React.FC<Props> = ({
    updateLoading,
    error,
    errorMessage,
    settings,
    setup,
    options,
    loadSettings,
    updateSettings,
    setSettingsState,
}) => {

    const [disableButton, setDisableButton] = useState(true)
    const [buttonText, setButtonText] = useState(i18n.t('general.save') as string)
    const [isSaved, setIsSaved] = useState(false)

    const {
        nomeSocial,
        genero,
        pronome,
        modoNoturno,
        filtroCor,
        contraste,
        tamanhoFonte,
        leituraLibras,
        leituraVoz,
        usarBiometria,
        aniversarioVida,
        isLoaded,
    } = settings

    const {
        showAcessibilidade = true,
        showSeguranca,
        showNotificacoes,
        settingsAvailable,
    } = setup

    useEffect(() => {
        loadSettings()
    }, [setup])

    const handleUpdate = () => {
        setDisableButton(true)
        updateSettings(settings)
        setIsSaved(true)
    }

    useEffect(() => {
        setDisableButton(false)
        setIsSaved(false)
    }, [
        nomeSocial,
        genero,
        pronome,
        modoNoturno,
        filtroCor,
        contraste,
        tamanhoFonte,
        leituraLibras,
        leituraVoz,
        usarBiometria,
        aniversarioVida,
        isLoaded
    ])

    useEffect(() => {
        return () => {
            updateSettings(settings)
        }
    }, [])

    useEffect(() => {
        if (!updateLoading && isSaved) setButtonText(i18n.t('general.saved'))
        if (!updateLoading && !isSaved) setButtonText(i18n.t('general.save'))
        if (updateLoading) setButtonText(`${i18n.t('general.saving')}`)
    }, [updateLoading, isSaved])

    return (
        <Loader loading={!isLoaded} error={error} errorMessage={errorMessage}>
            <Container>
                {setup.TituloModulo && (
                    <Breadcrumb show={true} TituloModulo={setup.TituloModulo} />
                )}
                <SettingsContainer>
                    <Profile
                        nomeSocial={nomeSocial}
                        genero={genero}
                        pronome={pronome}
                        options={options}
                        setSettingsState={setSettingsState}
                        settingsAvailable={settingsAvailable}
                    />
                    {showAcessibilidade && (
                        <Accessibility
                            modoNoturno={modoNoturno}
                            filtroCor={filtroCor}
                            contraste={contraste}
                            tamanhoFonte={tamanhoFonte}
                            libras={leituraLibras}
                            leituraVoz={leituraVoz}
                            setSettingsState={setSettingsState}
                            settingsAvailable={settingsAvailable}
                        />
                    )}

                    {showSeguranca && (
                        <Security
                            usarBiometria={usarBiometria}
                            setSettingsState={setSettingsState}
                            settingsAvailable={settingsAvailable}
                        />
                    )}

                    {showNotificacoes && (
                        <Notifications
                            aniversarioVida={aniversarioVida}
                            setSettingsState={setSettingsState}
                            settingsAvailable={settingsAvailable}
                        />
                    )}

                </SettingsContainer>
                <Button disabled={disableButton} isLoading={updateLoading} type='button' onClick={handleUpdate}>
                    <i className="fa fa-spinner fa-spin" style={{ display: updateLoading ? 'block' : 'none' }}></i>
                    {buttonText}
                </Button>
            </Container>
        </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.settings.loading,
    error: state.settings.error,
    errorMessage: state.settings.errorMessage,
    settings: state.settings.settings,
    setup: state.settings.setup,
    options: state.settings.options,
    updateLoading: state.settings.updateLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadSettings: () => dispatch(SettingsThunk.loadSettings() as any),

    updateSettings: (settings: ISettings) =>
        dispatch(SettingsThunk.updateSettings(settings) as any),

    setSettingsState: (field: TSettingsField, value: any) =>
        dispatch(setSettingsState(field, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen)
