import styled from 'styled-components';

export const PinContainer = styled.div`
    position: relative;
`;

export const PinRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap:8px;
    margin-bottom: 8px;
`;

export const PinButton = styled.button`
    font-family: Lato;
    font-size: 30px;
    font-weight: 700;
    background-color: ${props => props.theme.colors.primary};
    color: white;
    width: 100%;
    padding: 20px;
    border: none;
    border-radius: 5px;

    transition: filter 0.3s;

    :active{
        background-color: black;
    }
`;
