/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import {
    Container,
    ErrorMessage,
    ErrorTitle,
    LoadSpinner,
    Spinning,
    Wrapper,
} from './style'

type Props = {
    loading: boolean
    error?: boolean
    errorMessage?: string
    loadMessage?: string
    children: any
    show?: boolean
}

const Loader: React.FC<Props> = (props: Props) => {
    const { loading, error, errorMessage, children, show } = props

    const LoadComponent = () => {
        if (error) {
            return (
                <Container>
                    <ErrorTitle>Oops!!</ErrorTitle>
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                </Container>
            )
        }

        if (loading || show) {
            return (
                <Container>
                    <LoadSpinner />
                </Container>
            )
        }

        return <Wrapper>{children}</Wrapper>
    }

    return LoadComponent()
}

Loader.defaultProps = {
    show: false,
    error: false,
    errorMessage: 'Ocorreu um Erro!',
    loadMessage: 'Carregando...',
}

export default Loader
