import React from 'react'
import Switch from '../../../components/Switch'
import { useDebounce } from '../../../hooks/useDebounce'
import {
    ISecurityProps,
    TSettingsField,
} from '../../../store/containers/settings/types'
import { i18n } from '../../../translate/i18n'
import { FormContainer, FormGroup, Label, Title } from '../Screen/styles'

const Security: React.FC<ISecurityProps> = ({
    usarBiometria,
    setSettingsState,
    settingsAvailable,
}) => {
    const handleChange = useDebounce((field: TSettingsField, value: string) => {
        setSettingsState(field, value)
    }, 2000)

    if (!settingsAvailable.usarBiometria) return null

    return (
        <div>
            <Title>{i18n.t('settings.security')}</Title>
            <FormContainer>
                {settingsAvailable.usarBiometria && (
                    <>
                        <Label>{i18n.t('settings.app-activation')}</Label>
                        <FormGroup inline>
                            <Label
                                style={{
                                    color: '#767676',
                                    fontWeight: 'normal',
                                }}
                                htmlFor='usarBiometria'>
                                {i18n.t('settings.use-biometry')}
                            </Label>
                            <Switch
                                value={usarBiometria}
                                onChange={() =>
                                    handleChange(
                                        'usarBiometria',
                                        !usarBiometria
                                    )
                                }
                            />
                        </FormGroup>
                    </>
                )}
            </FormContainer>
        </div>
    )
}

export default Security
