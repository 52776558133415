import { Reducer } from "redux";
import { DocumentsState, DocumentsTypes } from "./types";

const INITIAL_STATE: DocumentsState = {
    documentFiles: [],
    documentFolders: [],
    loading: true,
    error: false,
    errorMessage: ""
}

const reducer: Reducer<DocumentsState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DocumentsTypes.LOAD_DOCUMENTS_SETUP:
            return { ...state, loading: false, error: false }
        case DocumentsTypes.LOAD_DOCUMENTS_REQUEST:
            return { ...state, loading: true }
        case DocumentsTypes.LOAD_FOLDERS_SUCCESS:
            return { ...state, loading: false, error: false, documentFolders: action.payload.data }
        case DocumentsTypes.LOAD_DOCUMENTS_SUCCESS:
            return { ...state, loading: false, error: false, documentFiles: action.payload.data }
        case DocumentsTypes.LOAD_FILE_SUCCESS:
            return { ...state, loading: false, error: false }
        case DocumentsTypes.LOAD_DOCUMENTS_FAILURE:
            return { ...state, loading: false, error: true, errorMessage: action.payload }
        default:
            return state

    }
}
 
export default reducer