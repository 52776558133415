import styled from 'styled-components';

export const StatusLabel = styled.label<{ color?: string }>`
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0px 10px;
  padding: 4px 8px;
  display: flex;
  align-items: center;

  background-color: ${props => props.color}20;

  margin-top: 10px;
  
  span {
    position: relative;
    color: ${props => props.color};
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;

    display: flex;
    align-items: center;
    line-height: 20px;
  }
`;
