import { Reducer } from 'redux'
import { BirthdaysState, BirthdaysTypes, IBirthdaySetup } from './types'

const INITIAL_STATE: BirthdaysState = {
    setup: {} as IBirthdaySetup,
    birthdays: [],
    loading: true,
    error: false,
    errorMessage: '',
}

const reducer: Reducer<BirthdaysState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BirthdaysTypes.LOAD_BIRTHDAYS_SETUP:
            return {
                ...state,
                loading: false,
                error: false,
                setup: action.payload.data,
            }
        case BirthdaysTypes.LOAD_BIRTHDAYS_REQUEST:
            return { ...state, loading: true }
        case BirthdaysTypes.LOAD_BIRTHDAYS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                birthdays: action.payload.data,
            }
        case BirthdaysTypes.LOAD_BIRTHDAYS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            }
        default:
            return state
    }
}

export default reducer
