import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
`

export const CoworkersIcon = styled.img`
    width: 190px;
    height: 150px;
`

export const Description = styled.h2`
    font-size: 24px;
    font-weight: normal;
    color: #767676;
    text-align: center;
`
