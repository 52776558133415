import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import DefaultUser from '../../assets/images/default-user-image.png'
import { ApplicationState } from '../../store'
import {
    IBirthworkItemProps,
    IBirthworkSetup,
} from '../../store/containers/birthwork/types'
import { i18n } from '../../translate/i18n'
import {
    CardContainer,
    ColCargo,
    ColNome,
    DateWrapper,
    Day,
    Since,
    UserInfoWrapper,
    UserPhoto,
    UserWrapper,
    YearContainer,
    Years,
    YearsInWork,
} from './styles'

interface StateProps {
    setup: IBirthworkSetup
}

type Props = StateProps & IBirthworkItemProps

const BirthworkItem: React.FC<Props> = ({ birthday, activeTab, setup }) => (
    <CardContainer key={birthday.colCodigo}>
        <UserWrapper>
            <UserPhoto
                src={birthday.colPhoto}
                alt=''
                onError={(e) => {
                    e.currentTarget.src = DefaultUser
                }}
            />
            <UserInfoWrapper>
                {activeTab === 1 && <Day>{birthday.diaSemana}</Day>}
                {activeTab === 2 && (
                    <Day>
                        {i18n.t('general.day')} {birthday.diaMes}
                    </Day>
                )}
                <ColNome>{birthday.colNome}</ColNome>
                <ColCargo>{birthday.colCargo}</ColCargo>
            </UserInfoWrapper>
        </UserWrapper>
        <DateWrapper>
            <Since>
                {i18n.t('general.since')} {birthday.anoContratacao}
            </Since>
            <YearContainer>
                <Years>
                    {moment().year() - Number(birthday.anoContratacao)}
                </Years>
                <YearsInWork>
                    {moment().year() - Number(birthday.anoContratacao) === 1
                        ? i18n.t('general.year')
                        : i18n.t('general.years')}
                </YearsInWork>
            </YearContainer>
        </DateWrapper>
    </CardContainer>
)

const mapStateToProps = (state: ApplicationState) => ({
    setup: state.birthdays.setup,
})

export default connect(mapStateToProps)(BirthworkItem)
