import React from 'react';
import SignatureCanvas from 'react-signature-canvas'

import { Container, SignatureButtons, SignButton } from './style'
import './style.css'

type Props = { setSignData(signData: string): void, setIsSigned(isSigned: boolean): void }

const Signature: React.FC<Props> = (props: Props) => {
    const { setSignData, setIsSigned } = props

    let sigPad: SignatureCanvas | null = null

    const clearSigPad = () => {
        sigPad?.clear()
        setSignData('')
        setIsSigned(false)
    }

    const sigPadWidth = window.innerWidth - 40
    const sigPagHeight = (window.innerWidth / 4) * 2

    const getSignature = () => {
        if (sigPad && !sigPad?.isEmpty()) {
            setSignData(sigPad.getTrimmedCanvas().toDataURL())
            setIsSigned(true)
        }
    }
    return (
        <Container>
            <SignatureCanvas canvasProps={{ width: sigPadWidth, height: sigPagHeight, className: 'sigpad' }} ref={ref => { sigPad = ref }} onEnd={getSignature} />
            <SignatureButtons>
                <SignButton onClick={clearSigPad}>Limpar</SignButton>
            </SignatureButtons>
        </Container>
    )
}

export default Signature