import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 45px 0 ;
    gap: 5px;
`

export const SellerPhoto = styled.img`
    height: 56px;
    width: 56px;

    border: 3px solid #fff;
    border-radius: 50%;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.12));
`
export const SellerHeader = styled.div`
    position: relative;
`

export const SellerName = styled.h2`
    font-size: 16px;
    font-weight: 800;
`

export const SellerRole = styled.p`
    font-size: 12px;
    font-weight: 400;
    text-align: center;
`

export const SellerMail = styled.p`
    font-size: 12px;
    font-weight: 400;
`

export const SellerPhone = styled.p`
    font-size: 12px;
    font-weight: 400;
`

export const SendMessage = styled.button`
    width: 100%;
    position: absolute;
    left: 0;

    border: none;
    background-color: ${props => props.theme.colors.primary};

    font-size: 18px;
    font-weight: 700;
    color: #fff;
    padding: 20px 0;

`