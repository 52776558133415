/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import {
    IProfileProps,
    TSettingsField,
} from '../../../store/containers/settings/types'
import { i18n } from '../../../translate/i18n'
import {
    FormContainer,
    FormGroup,
    Input,
    Label,
    Select,
    Title,
} from '../Screen/styles'

const Profile: React.FC<IProfileProps> = ({
    nomeSocial,
    genero,
    pronome,
    options,
    setSettingsState,
    settingsAvailable,
}) => {

    const handleChange = (field: TSettingsField, value: string) => {
        setSettingsState(field, value)
    }

    if (
        !settingsAvailable.nomeSocial &&
        !settingsAvailable.genero &&
        !settingsAvailable.pronome
    )
        return null

    return (
        <div>
            <Title>{i18n.t('settings.profile')}</Title>
            <FormContainer>
                {settingsAvailable.nomeSocial && (
                    <FormGroup>
                        <Label htmlFor='nomeSocial'>
                            {i18n.t('settings.social-name')}
                        </Label>
                        <Input
                            placeholder={i18n.t('settings.not-specified')}
                            value={nomeSocial}
                            type='text'
                            id='nomeSocial'
                            name='nomeSocial'
                            onChange={(event) =>
                                handleChange(
                                    'nomeSocial',
                                    event.currentTarget.value
                                )
                            }
                        />
                    </FormGroup>
                )}

                {settingsAvailable.genero && (
                    <FormGroup>
                        <Label htmlFor='genero'>
                            {i18n.t('settings.genero')}
                        </Label>
                        <Select
                            value={genero}
                            name='genero'
                            id='genero'
                            onChange={(event) =>
                                handleChange(
                                    'genero',
                                    event.currentTarget.value
                                )
                            }>
                            {options.genders &&
                                options.genders.map((g) => (
                                    <option key={g.value} value={g.value || ''}>
                                        {g.text}
                                    </option>
                                ))}
                        </Select>
                    </FormGroup>
                )}

                {settingsAvailable.pronome && (
                    <FormGroup>
                        <Label htmlFor='pronome'>
                            {i18n.t('settings.pronome')}
                        </Label>
                        <Select
                            value={pronome}
                            name='pronome'
                            id='pronome'
                            onChange={(event) =>
                                handleChange(
                                    'pronome',
                                    event.currentTarget.value
                                )
                            }>
                            {options.pronouns &&
                                options.pronouns.map((p) => (
                                    <option key={p.value} value={p.value}>
                                        {p.text}
                                    </option>
                                ))}
                        </Select>
                    </FormGroup>
                )}
            </FormContainer>
        </div>
    )
}

export default Profile
