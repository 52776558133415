import styled from 'styled-components';

export const TabContainer = styled.div`
    position: relative;
    margin-bottom: 1px;
`
export const TabList = styled.ul`
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 25px 15px; 
    list-style: none;
`;

export const TabButton = styled.button<{ isActive?: boolean }>`
    background-color: transparent;
    border: none;

    font-size: 17px;
    font-weight: 600;
    color: ${props => props.theme.colors.text};

    opacity: ${props => props.isActive ? 1 : 0.4};
`
export const ActiveTab = styled.div<{ width: number, position: number } >`
    position: absolute;
    bottom: 0;
    left: ${props => props.position}px;
    width:${props => props.width}px;
    height: 2px;

    background-color: ${props => props.theme.colors.primary};

    transition: linear 0.3s;
`