import React from 'react'
import GiftPinGrid from '../components/pin-grid'
import { i18n } from '../../../translate/i18n'

import { Container, Note, GiftButton } from './style'

type Props = {
    pinValue: string
    setPinValue: (pinValue: string) => void
    setPinScreen: (pinScreen: boolean) => void
    RequestGift: () => void
}

const PinScreen: React.FC<Props> = ({
    pinValue,
    setPinValue,
    setPinScreen,
    RequestGift,
}: Props) => (
    <Container>
        <Note>
            <span>{i18n.t('gifts.for-company')}</span>
            {i18n.t('gifts.pin-note')}
        </Note>
        <GiftPinGrid
            pinValue={pinValue}
            setPinValue={setPinValue}
            requestedPIN={RequestGift}
        />
        <GiftButton onClick={() => setPinScreen(false)}>
            {i18n.t('general.goBack')}
        </GiftButton>
    </Container>
)

export default PinScreen
