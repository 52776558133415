import styled from 'styled-components';

export const Container = styled.div`
margin-top: 10px;  
`;

export const Canvas = styled.canvas`
    height: 200px;
    width: 100%;
    border: #ccc solid 1px;
    border-radius: 5px;
`

export const SignatureButtons = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`

export const SignButton = styled.button`
    display: block;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    padding: 10px;

    background-color: ${props => props.disabled ? '#999' : props.theme.colors.primary};
    color: white; 
`

export const ConfirmButton = styled.button``