import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Dispatch } from 'redux';
import { Carousel } from 'react-responsive-carousel';
import { ApplicationState } from '../../../store';
import ClassifiedThunk from '../../../store/containers/classified/thunk';
import { IAnnouncement, IClassifiedSeller } from '../../../store/containers/classified/types';

import Breadcrumb from '../../../components/Breadcrumb';
// import UserCard from '../../../components/UserCard';
import Loader from '../../../components/Loader';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Container, Infos, InfoType, InfoDate, Header, Title, Price, Description, Divider, CarouselPlaceHolder, Labels, InfoTrade } from './style';
import defaultImage from '../../../assets/images/default.jpg'
import SellerCard from './components/SellerCard';

interface IParams {
    id: string
}

interface StateProps {
    announcement: IAnnouncement,
    loading: boolean,
    error: boolean,
    seller: IClassifiedSeller
}
interface DispatchProps {
    loadAnnouncement: (id: number) => void
    loadSeller: (id: number) => void
    loadRequest: () => void
}

type Props = StateProps & DispatchProps

const ClassifiedDetail: React.FC<Props> = (props: Props) => {

    const { id } = useParams<IParams>()

    useEffect(() => {
        const { loadAnnouncement, loadRequest } = props
        loadRequest()
        loadAnnouncement(Number(id))
    }, [id])

    const { announcement, loading, error } = props

    useEffect(() => {
        const { loadSeller } = props
        if (announcement.CodigoClassificadoItem)
            loadSeller(announcement.CodigoClassificadoItem)
    }, [announcement])

    const imagesCarousel = useCallback(() => {
        const imageArray = []

        if (announcement.Imagem1) {
            imageArray.push(announcement.Imagem1)
        }
        if (announcement.Imagem2) {
            imageArray.push(announcement.Imagem2)
        }
        if (announcement.Imagem3) {
            imageArray.push(announcement.Imagem3)
        }

        if (imageArray.length === 0) {
            return (
                <CarouselPlaceHolder>
                    <img src={defaultImage} alt="" />
                </CarouselPlaceHolder>
            )
        }

        return (
            <Carousel showThumbs={false} showStatus={false}>
                {imageArray.map((element, index) =>
                    <div key={index}>
                        <img src={element} alt="" />
                    </div>
                )}
            </Carousel>
        )
    }, [announcement])

    const { seller } = props

    return (
        <>
            <Breadcrumb>Detalhe do anúncio</Breadcrumb>
            <Loader loading={loading} error={error}>
                <Container>
                    <Divider />
                    <Infos>
                        <InfoType tipoUso={1} >PRODUTO {announcement.TipoUso === 1 ? 'NOVO' : 'USADO'} </InfoType>
                        {
                            announcement.DataCriacao &&
                            <InfoDate>Cadastrado em {announcement.DataCriacao}</InfoDate>
                        }
                    </Infos>
                    <Header>
                        <Title>{announcement.Titulo}</Title>
                        <Price>R$ {announcement.Valor}</Price>
                    </Header>

                    {imagesCarousel()}
                    <Description>
                        <h3>Descrição</h3>
                        <p>
                            {announcement.Descricao}
                        </p>
                    </Description>
                    <Labels>
                        {announcement.AceitaTroca && <InfoTrade AceitaTroca={announcement.AceitaTroca}>ACEITO TROCA</InfoTrade>}
                    </Labels>
                    <Divider />
                    <SellerCard seller={seller} />
                </Container>
            </Loader>
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    announcement: state.classifieds.actualAnnouncement,
    loading: state.classifieds.loading,
    error: state.classifieds.error,
    seller: state.classifieds.seller
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadRequest: () => dispatch({ type: "@classified/LOAD_CLASSIFIED_REQUEST" }),
    loadAnnouncement: (id: number) => dispatch(ClassifiedThunk.loadAnnouncement(id) as any),
    loadSeller: (id: number) => dispatch(ClassifiedThunk.loadSellerData(id) as any)
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassifiedDetail);