/**
 * Action Types
 */

// eslint-disable-next-line no-shadow
export enum TermTypes {
    LOAD_TERM_SETUP = "@terms/LOAD_TERMS_SETUP",
    LOAD_TERM_REQUEST = "@terms/LOAD_TERM_REQUEST",
    LOAD_TERM_SUCCESS = "@terms/LOAD_TERM_SUCCESS",
    LOAD_TERM_FAILURE = "@terms/LOAD_TERM_FAILURE",

    LOAD_SINGLE_TERM_SUCCESS = "@terms/LOAD_SINGLE_TERM_SUCCESS",

    COMPLETE_TERM_REQUEST = "@terms/COMPLETE_TERM_REQUEST",
    COMPLETE_TERM_SUCCESS = "@terms/COMPLETE_TERM_SUCCESS",
    COMPLETE_TERM_FAILURE = "@terms/COMPLETE_TERM_FAILURE"
}

/** 
 * Data Types
*/

export interface ITerm {
    codigo: number,
    titulo: string,
    subtitulo: string,
    status: number,
    imagem: string,
    conteudo: string,
    requerAssinatura: boolean
}

/**
 * State Types
 */

export interface TermState {
    readonly terms: ITerm[]
    readonly activeTerm: ITerm
    readonly loading: boolean
    readonly error: boolean
    readonly errorMessage: string
} 