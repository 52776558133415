import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import { Dispatch } from 'redux'
import Loader from '../../components/Loader'
import { ApplicationState } from '../../store'
import BirthworkThunk from '../../store/containers/birthwork/thunk'

interface StateProps {
    loading: boolean
    error: boolean
}

interface DispatchProps {
    loadSetup: (hash: string) => void
}

type Props = StateProps & DispatchProps

const Birthwork: React.FC<Props> = (props: Props) => {
    const { loading, error } = props
    const { hash } = useLocation()

    useEffect(() => {
        const { loadSetup } = props
        loadSetup(hash)
    }, [])

    return (
        <Loader loading={loading} error={error}>
            <Redirect to={{ pathname: '/birthwork/list' }} />
        </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.birthwork.loading,
    error: state.birthwork.error,
    theme: state.app.theme,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadSetup: (hash: string) =>
        dispatch(BirthworkThunk.loadSetup(hash) as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(Birthwork)
