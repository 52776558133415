import { action } from 'typesafe-actions'
import { TermTypes } from './types'

export const loadTermsSetup = () => action(TermTypes.LOAD_TERM_SETUP)

export const loadTermRequest = () => action(TermTypes.LOAD_TERM_REQUEST)
export const loadTermSuccess = (data: any) =>
    action(TermTypes.LOAD_TERM_SUCCESS, { data })
export const loadTermFailure = (error: any) =>
    action(TermTypes.LOAD_TERM_FAILURE, error)

export const loadSingleTermSuccess = (data: any) =>
    action(TermTypes.LOAD_SINGLE_TERM_SUCCESS, { data })

export const completeTermRequest = () => action(TermTypes.COMPLETE_TERM_REQUEST)
export const completeTermSuccess = (data: any) =>
    action(TermTypes.COMPLETE_TERM_SUCCESS, { data })
export const completeTermFailure = (error: any) =>
    action(TermTypes.COMPLETE_TERM_FAILURE, { error })
