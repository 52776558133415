/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement } from 'react';

import { Container, Root, Input, Fill } from './style';

type RadioGroupProps = {
    name: string,
    selectedValue: string,
    onClickRadioButton(event: React.ChangeEvent<any>): void,
    children: ReactElement[]
}

export const RadioGroup: React.FC<RadioGroupProps> = (props: RadioGroupProps) => {
    const { name, selectedValue, onClickRadioButton, children } = props
    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        <Container role="radiogroup" name={name} >
            {React.Children.map(children, element =>
                React.cloneElement(element, {
                    ...element.props,
                    checked: selectedValue === element.props.value,
                    onChange: onClickRadioButton,
                    name
                })
            )}
        </Container>
    )
}

type RadioProps = {
    onChange?: any,
    value: string,
    labelText: string,
    checked?: boolean,
    name?: string
}

export const Radio: React.FC<RadioProps> = (props: RadioProps) => {
    const { onChange, value, labelText, checked, name } = props;

    return (
        <Root >
            <label htmlFor={name}>
                {labelText}
                <Input
                    type="radio"
                    name={name}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    aria-checked={checked}
                />
                <Fill />
            </label>
        </Root>
    )
}

Radio.defaultProps = {
    checked: false,
    name: "",
    onChange: () => { }
}