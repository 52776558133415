import { action } from "typesafe-actions";
import { AppTypes, ITheme } from "./types";

export const loadUploadRequest = () => action(AppTypes.LOAD_UPLOAD_REQUEST)
export const loadUploadFailure = (error: any) => action(AppTypes.LOAD_UPLOAD_FAILURE)

export const loadTokenRequest = () => action(AppTypes.LOAD_APP_TOKEN_REQUEST)
export const loadTokenSuccess = () => action(AppTypes.LOAD_APP_TOKEN_SUCCESS)
export const loadTokenFailure = (error: string) => action(AppTypes.LOAD_APP_TOKEN_FAILURE, error)

export const setAppTheme = (theme: ITheme) => action(AppTypes.SET_APP_THEME, { theme })

export const setAppService = (data: string) => action(AppTypes.SET_APP_SERVICE, data)