export const postMessageToRN = (message: string) => {
    try {
        if (window.parent && window.parent.window && window.parent.window.postMessage) {
            window.parent.window.postMessage(message, '*')
            return true
        }

        if (window.ReactNativeWebView?.postMessage) {
            window.ReactNativeWebView.postMessage(message)
            return true
        } 
        
        if (window.onPostMessage) {
            window.onPostMessage(message)
            return true
        }
        
        if (window.postMessage) {
            window.postMessage(message, '*')
            return true
        } 

        return false
    } catch (error) {
        return false
    }
}

export const isIOS = () => [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
].includes(navigator.platform)