import styled from 'styled-components'

export const TopBar = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    padding: 4px;
`

export const Container = styled.div`
    padding: 24px;
`

export const GiftQueueContainer = styled.div<{ flex?: boolean }>`
    display: ${(props) => (props.flex ? 'flex' : 'block')};
    gap: 8px;
    position: relative;
    margin-bottom: 24px;
`

export const GiftIcon = styled.div`
    display: flex;
    align-items: center;
    > svg {
        width: 40px;
        height: 40px;
    }
`

export const Note = styled.p`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 21px;

    > span {
        font-weight: 800;
        color: ${(props) => props.theme.colors.primary};
    }
`

export const Separator = styled.hr`
    width: 105%;
    background-color: #a0a0a0;
    border: 1px solid #a0a0a0;
    margin-left: -2.5%;
    margin-bottom: 25px;
`

export const GiftNote = styled.div`
    position: relative;
    margin-top: 25px;
`

export const GiftButton = styled.button<{ fill?: boolean }>`
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.colors.primary};
    border-radius: 5px;
    background-color: ${(props) =>
        props.fill ? props.theme.colors.primary : 'white'};

    color: ${(props) => (props.fill ? 'white' : props.theme.colors.primary)};

    > a {
        width: 100%;
        display: block;
        text-align: center;

        color: inherit;
    }
`
