import styled, { keyframes } from 'styled-components'

const appear = keyframes`
    from {
        opacity: 0;
        transform: rotate(0deg)
    }
    to {
        opacity: 1;
        transform: rotate(360deg)
    }
`

const showUP = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const LoadSpinner = styled.div`
    border-width: 4px;
    border-style: solid;
    border-color: ${({ theme }) =>
        theme.colors.primary + '#f4f4f4' + '#f4f4f4' + '#f4f4f4'};
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    -webkit-animation: spin 2s infinite;
    animation: spin 2s infinite;

    margin-bottom: 80px;

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }
`

export const Spinning = styled.i`
    font-size: 40px;
    color: #888;
    margin-bottom: 80px;
    animation: ${appear} 2s ease-in-out infinite;
`
export const Text = styled.h2`
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
`

export const ErrorTitle = styled.h2`
    font-size: 20px;
    font-weight: 300;
    color: #f00;
`

export const ErrorMessage = styled.h3`
    font-size: 16px;
    font-weight: 400;
    color: #000;
`

export const Wrapper = styled.div`
    animation: ${showUP} 2s ease-in-out;
`
