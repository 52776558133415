import { Reducer } from 'redux'
import { ISettingsOptions, SettingsState, SettingsTypes } from './types'

const INITIAL_STATE = {
    settings: {
        nomeSocial: '',
        genero: '',
        pronome: '',
        modoNoturno: false,
        filtroCor: '',
        contraste: '',
        tamanhoFonte: '',
        leituraLibras: false,
        leituraVoz: false,
        usarBiometria: false,
        aniversarioVida: false,
        isLoaded: false,
    },
    setup: {
        showAcessibilidade: false,
        showSeguranca: false,
        showNotificacoes: false,
        settingsAvailable: {
            nomeSocial: false,
            genero: false,
            pronome: false,
            modoNoturno: false,
            filtroCor: false,
            contraste: false,
            tamanhoFonte: false,
            leituraLibras: false,
            leituraVoz: false,
            usarBiometria: false,
            aniversarioVida: false,
        },
        TituloModulo: '',
        CorPrimaria: '',
        CorSecundaria: '',
        EsconderHeader: false,
        isLoaded: false,
    },
    options: {} as ISettingsOptions,
    pronome: '',
    loading: true,
    updateLoading: false,
    error: false,
    errorMessage: '',
}

const reducer: Reducer<SettingsState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SettingsTypes.LOAD_SETTINGS_REQUEST:
            return { ...state, loading: true }
        case SettingsTypes.LOAD_SETTINGS_SETUP:
            return {
                ...state,
                loading: false,
                error: false,
                setup: action.payload.data,
            }
        case SettingsTypes.LOAD_SETTINGS_SUCCESS:
            return {
                ...state,
                error: false,
                settings: action.payload.data,
            }
        case SettingsTypes.LOAD_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            }
        case SettingsTypes.LOAD_SETTINGS_OPTIONS:
            return {
                ...state,
                loading: false,
                error: false,
                options: action.payload.data,
            }
        case SettingsTypes.SET_SETTINGS_STATE:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [action.payload.field]: action.payload.value,
                },
            }
            case SettingsTypes.UPDATE_SETTINGS:
                return {
                    ...state,
                    updateLoading: action.payload.loading,
                }
        default:
            return state
    }
}

export default reducer
