const messages = {
    es: {
        translations: {
            general: {
                goBack: 'Volver',
                day: 'Día',
                week: 'Semana',
                month: 'Mes',
                years: 'Años',
                year: 'Año',
                since: 'Desde',
                save: 'Guardar',
                saving: 'Guardando...',
                saved: 'Guardado!',
            },
            birthdays: {
                notFound: 'No hay cumpleañeros en este período',
            },
            birthwork: {
                notFound: 'Ninguna celebración en este período',
            },
            documents: {
                see: 'Ver',
            },
            training: {
                todo: 'A Realizar',
                done: 'Realizados',
                'your-progress': 'Su Progreso',
            },
            terms: {
                pendings: 'Pendientes',
                accepted: 'Aceptados',
            },
            gifts: {
                'for-company': 'Para uso exclusivo de la empresa:',
                'pin-note':
                    'Proporcione el PIN de acceso para confirmar la entrega.',
                'last-rescue': 'Fecha del último rescate:',
                'no-last-rescue': 'Ningún rescate solicitado previamente',
            },
            settings: {
                profile: 'Perfil',
                'social-name': 'Nombre social',
                'not-specified': 'No especificado',
                genero: 'Género',
                'genero-list': {
                    masculine: 'Masculino',
                    feminine: 'Femenino',
                    'non-binary': 'No binario',
                    other: 'Otro',
                },
                pronome: 'Pronombres',
                accessibility: 'Accesibilidad',
                'dark-mode': 'Modo oscuro',

                'color-filter': 'Filtro de color',
                'color-filter-protoanopia': 'Protanopia',
                'color-filter-deuteranopia': 'Deuteranopia',
                'color-filter-tritanopia': 'Tritanopia',

                contraste: 'contrastee',
                'contraste-low': 'Bajo',
                'contraste-normal': 'Normal',
                'contraste-high': 'Alto',

                'font-size': 'Tamaño de fuente',
                'font-size-small': 'Pequeño',
                'font-size-normal': 'Normal',
                'font-size-large': 'Grande',

                libras: 'Libras',
                'text-to-speech': 'Lectura de texto',

                security: 'Seguridad',
                'app-activation': 'Activación de la aplicación',
                'use-biometry': 'Usar biometría',

                notifications: 'Avisos y Notificaciones',
                birthdays: 'Cumpleaños',
                'allow-birthdays': 'Permitir divulgaciones',
            },
        },
    },
}

export { messages }
