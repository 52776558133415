import React from 'react';

import { ActiveTab } from './style';

type Props = {
    width: number,
    position: number
}

const TabActive: React.FC<Props> = ({ width, position }: Props) => (
    <ActiveTab width={width} position={position} />
)

export default TabActive;