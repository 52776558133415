import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
`;

export const Input = styled.input`
    display: none;
`

export const Button = styled.button<{ width?: number }>`
    background-color: transparent;
    padding: 20px 32px;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    width: ${props => props.width}%;
    height: 70px;

    img{
        max-width: 100%;
        max-height: 100%;
    }
`

export const UploadedImageCard = styled.div<{ width?: number }>`
    width: ${props => props.width}%;
`

export const UploadedImage = styled.div`
    width: 100%;
    height: 70px;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >img {
        max-width: 100%;
        max-height: 100%;
    }
`

export const DeleteUploadedImage = styled.button`
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 15px;

    >img {
        max-width: 100%;
        border: none;
    }
`