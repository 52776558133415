import { Reducer } from "redux";
import { AppState, AppTypes, ITheme } from "./types";

const INITIAL_STATE: AppState = {
    uploadLoading: false,
    uploadError: false,
    loading: true,
    error: false,
    errormessage: "",
    service: "",
    theme: {} as ITheme
}

const reducer: Reducer<AppState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AppTypes.LOAD_APP_TOKEN_REQUEST:
            return { ...state, loading: true, error: false }
        case AppTypes.LOAD_APP_TOKEN_SUCCESS:
            return { ...state, loading: false, error: false }
        case AppTypes.LOAD_UPLOAD_REQUEST:
            return { ...state, uploadLoading: true, uploadError: false }
        case AppTypes.LOAD_UPLOAD_FAILURE:
            return { ...state, uploadError: true, uploadLoading: false }
        case AppTypes.LOAD_UPLOAD_SUCCESS:
            return { ...state, uploadError: false, uploadLoading: false }
        case AppTypes.LOAD_APP_TOKEN_FAILURE:
            return { ...state, loading: false, error: true, errormessage: `Login - ${action.payload}` }
        case AppTypes.SET_APP_THEME:
            return { ...state, theme: action.payload.theme }
        case AppTypes.SET_APP_SERVICE:
            return { ...state, service: action.payload.data }
        default:
            return state
    }
}

export default reducer