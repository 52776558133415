import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { ApplicationState } from '../../store'
import ClassifiedThunk from '../../store/containers/classified/thunk'
import ClassifiedList from './list'
import Loader from '../../components/Loader'

interface StateProps {
    loading: boolean
    error: boolean
}
interface DispatchProps {
    loadSetup(): void
}

type Props = DispatchProps & StateProps

const Classified: React.FC<Props> = (props: Props) => {
    const { loading, error } = props

    useEffect(() => {
        const { loadSetup } = props
        loadSetup()
    }, [])
    return (
        // <Loader loading={loading} error={error}>
        <ClassifiedList />
        // </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.classifieds.loading,
    error: state.classifieds.error,
    theme: state.app.theme,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadRequest: () => dispatch({ type: '@training/LOAD_TRAINING_REQUEST' }),
    loadSetup: () => dispatch(ClassifiedThunk.loadSetup() as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(Classified)
