import React from 'react'
import { useHistory } from 'react-router-dom'
import {
    BreadcrumbContainer,
    BreadcrumbPage,
    BreadcrumbBack,
    BreadcrumbWrapper,
} from './style'

import back from '../../assets/images/back.svg'
import { isIOS } from '../../utils'
import { i18n } from '../../translate/i18n'

type Props = {
    show?: boolean
    children?: React.ReactNode
    TituloModulo?: string
}

const Breadcrumb: React.FC<Props> = ({ show, TituloModulo }: Props) => {
    const history = useHistory()

    const hideHeader = localStorage
        .getItem(`@services_header`)
        ?.toLocaleLowerCase()
        .trim()
    const pageTitle =
        TituloModulo || localStorage.getItem(`@services_page_title`)

    const goBack = () => {
        if (isIOS()) {
            window.history.go(-1)
            return false
        }
        return history.goBack()
    }

    return (
        <>
            {hideHeader === 'false' && (
                <BreadcrumbContainer>
                    <BreadcrumbWrapper>
                        <BreadcrumbPage
                            dangerouslySetInnerHTML={{
                                __html: String(pageTitle),
                            }}
                        />
                        {show && (
                            <BreadcrumbBack onClick={() => goBack()}>
                                <img src={back} alt='' />
                                {i18n.t('general.goBack')}
                            </BreadcrumbBack>
                        )}
                    </BreadcrumbWrapper>
                </BreadcrumbContainer>
            )}
        </>
    )
}

Breadcrumb.defaultProps = {
    show: false,
}

export default Breadcrumb
