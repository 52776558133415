import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh; 
    position: relative;
    padding: 16px;
`
export const SettingsContainer = styled.div`
    position: relative;
`

export const Title = styled.h2`
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 24px;
`

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 32px;
`

export const FormGroup = styled.div<{ inline?: boolean }>`
    display: flex;
    flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
    justify-content: ${({ inline }) =>
        inline ? 'space-between' : 'flex-start'};

    width: 100%;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #e0e0e0;
`

export const Label = styled.label`
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.textLight};
    margin-bottom: 8px;
`

export const Input = styled.input`
    width: 100%;
    height: 18px;
    font-size: 14px;
    font-weight: 400;
    border: none;
    color: ${({ theme }) => theme.colors.textLight};
    background-color: ${({ theme }) => theme.colors.background};
    transition: border-color 0.2s ease-in-out;
    &:focus {
        outline: none;
    }
`

export const Select = styled.select`
    width: 100%;
    height: 18px;
    font-size: 14px;
    font-weight: 400;
    border: none;
    color: ${({ theme }) => theme.colors.textLight};
    background-color: ${({ theme }) => theme.colors.background};
    transition: border-color 0.2s ease-in-out;
    &:focus {
        outline: none;
    }
`

export const Button = styled.button<{ isLoading: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 16px 16px;
    font-size: 16px;
    font-weight: 700;
    color:#FFFFFF;
    background-color: ${({ theme }) =>  theme.colors.primary};
    border: none;
    cursor: pointer;
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    border-radius: 4px;
    :disabled {
        background-color: ${({ theme, isLoading }) => isLoading ? theme.colors.secondary : '#969696'};
        cursor: not-allowed;
    }
`