import { Reducer } from 'redux'
import { BirthworkState, IBirthworkSetup, BirthworkTypes } from './types'

const INITIAL_STATE: BirthworkState = {
    setup: {} as IBirthworkSetup,
    birthwork: [],
    loading: true,
    error: false,
    errorMessage: '',
}

const reducer: Reducer<BirthworkState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BirthworkTypes.LOAD_BIRTHWORK_SETUP:
            return {
                ...state,
                loading: false,
                error: false,
                setup: action.payload.data,
            }
        case BirthworkTypes.LOAD_BIRTHWORK_REQUEST:
            return { ...state, loading: true }
        case BirthworkTypes.LOAD_BIRTHWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                birthwork: action.payload.data,
            }
        case BirthworkTypes.LOAD_BIRTHWORK_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            }
        default:
            return state
    }
}

export default reducer
