import React, { useEffect } from 'react'
import { Redirect, Route, RouteProps, useLocation } from 'react-router'
import { isAuthenticated } from '../auth/auth'
import AuthRoute from '../auth/AuthRoute'
import { postMessageToRN } from '../utils'

const PrivateRoute: React.FC<RouteProps> = ({ ...routeProps }) => {
    const { hash, pathname } = useLocation()

    // useEffect(() => {
    //     const level = pathname.split('/').length - 2
    //     postMessageToRN(`#level=${level}`)
    // }, [pathname])

    const AuthMethod = () => {
        if (isAuthenticated()) {
            if (hash) {
                return <AuthRoute {...routeProps} />
            }
            return <Route {...routeProps} />
        }
        if (hash) {
            return <AuthRoute {...routeProps} />
        }

        return (
            <Redirect
                to={{
                    pathname: '/error',
                    state: { errorMessage: 'Autenticação inválida' },
                }}
            />
        )
    }

    return <>{AuthMethod()}</>
}
export default PrivateRoute
