import React from 'react';
import Skeleton from 'react-loading-skeleton';

import {
    Card,
    CardBody,
    CardTitle,
    CardDepartment,
    CardButton,
} from "./style";


const trainingCardSkeleton: React.FC = () => (
    <Card status={1}>
        <Skeleton circle height={68} width={68} />
        <CardBody>
            <CardTitle><Skeleton width={200} /></CardTitle>
            <CardDepartment><Skeleton width={100} /></CardDepartment>
            <CardButton status={0}>
                <Skeleton width={50} />
            </CardButton>
        </CardBody>
    </Card>
)

export default trainingCardSkeleton;