import thunk from 'redux-thunk'
import { applyMiddleware, Store, createStore, compose } from 'redux'
import * as Sentry from '@sentry/react'
import rootReducer from './containers/rootReducer'
import { TrainingState } from './containers/training/types'
import { ClassifiedState } from './containers/classified/types'
import { UserState } from './containers/user/types'
import { AppState } from './containers/app/types'
import { TermState } from './containers/terms/types'
import { DocumentsState } from './containers/documents/types'
import { GiftState } from './containers/gifts/types'
import { BirthdaysState } from './containers/birthdays/types'
import { BirthworkState } from './containers/birthwork/types'
import { SettingsState } from './containers/settings/types'

export interface ApplicationState {
    app: AppState
    trainings: TrainingState
    terms: TermState
    classifieds: ClassifiedState
    user: UserState
    documents: DocumentsState
    gifts: GiftState
    birthdays: BirthdaysState
    birthwork: BirthworkState
    settings: SettingsState
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

const store: Store<ApplicationState> = createStore(
    rootReducer,
    compose(applyMiddleware(thunk), sentryReduxEnhancer)
)

export default store
