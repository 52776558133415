import React, { SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import { ITraining } from "../../../../../store/containers/training/types";
import defaulTrainingImage from "../../../../../assets/images/default.jpg"

import {
  Card,
  CardImage,
  CardBody,
  CardTitle,
  CardDepartment,
  CardButton,
  trainingButtons
} from "./style";

type Props = { 
  training: ITraining;
};

const Item: React.FC<Props> = (props: Props) => {
  const { training } = props;

  function imageError(ev: SyntheticEvent<HTMLImageElement, Event>) {
    // eslint-disable-next-line no-param-reassign
    ev.currentTarget.src = defaulTrainingImage
  }

  return (
    <Card status={training.status}>
      <Link to={{ pathname: `/training/show/${training.codigo}` }}>
        <CardImage onError={imageError} src={training.imagem} />
        <CardBody>
          <CardTitle>{training.titulo}</CardTitle>
          <CardDepartment>{training.subtitulo}</CardDepartment>
          <CardButton status={training.status}>
            <img src={trainingButtons.find(button => button.key === training.status)?.icon} alt="" className="" />
            <span>
              {trainingButtons.find(button => button.key === training.status)?.text}
            </span>
          </CardButton>
        </CardBody>
      </Link>
    </Card>
  );
};

export default Item;
