// eslint-disable-next-line no-shadow
export enum BirthdaysTypes {
    LOAD_BIRTHDAYS_SETUP = '@birthdays/LOAD_BIRTHDAYS_SETUP',

    LOAD_BIRTHDAYS_REQUEST = '@birthdays/LOAD_BIRTHDAYS_REQUEST',
    LOAD_BIRTHDAYS_SUCCESS = '@birthdays/LOAD_BIRTHDAYS_SUCCESS',
    LOAD_BIRTHDAYS_FAILURE = '@birthdays/LOAD_BIRTHDAYS_FAILURE',
}

export type TBirthdayType = 'birthdays' | 'birthwork'

// TODO: Create a common type for Setup
export interface IBirthdaySetup {
    ShowCargo: boolean
    ShowPhoto: boolean
    TituloModulo: string
    CorPrimaria: string
    CorSecundaria: string
    CardTitulo: string
    CardDescricao: string
    EsconderHeader: boolean
}

export interface IBirthday {
    colCodigo: string | number
    colNome: string
    colCargo: string
    colPhoto: string
    diaMes: string
    diaSemana: string
    naSemana: boolean
    noDia: boolean
    isPlaceholder?: boolean
}

// Props
export interface IBirthdaysItemProps {
    birthday: IBirthday
    activeTab: number
}

export interface BirthdaysState {
    readonly setup: IBirthdaySetup
    readonly birthdays: IBirthday[]
    readonly loading: boolean
    readonly error: boolean
    readonly errorMessage: string
}
