import styled from 'styled-components';

export const Container = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    font-size: .75em;
    font-weight: 700;
    color: #fff;
    background-color: ${props => props.theme.colors.danger};
`

export const Value = styled.p`
    line-height: 20px;
`