import { AxiosResponse, AxiosError } from "axios"
import { Dispatch } from "redux"
import * as Sentry from "@sentry/react";
import { asyncLocalStorage } from "../../../auth/auth"
import { api } from "../../../services/api"
import simplifica from "../../../styles/themes/simplifica"
import * as TrainingActions from "./actions"

const TrainingThunk = {
    loadSetup: (hash: string) => (dispatch: Dispatch) => {
        dispatch(TrainingActions.loadTrainingRequest())
        api.post("/treinamentos/setup", { hash })
            .then((response: AxiosResponse) => {
                if (response.data?.state) {
                    TrainingThunk.loadTheme(response.data.data)
                    asyncLocalStorage.setItem("@services_header", JSON.parse(response.data.data)[0].EsconderHeader)
                        .then(() => dispatch(TrainingActions.loadTrainingSetup()))
                } else {
                    Sentry.captureException("SETUP - Erro no Carregamento");
                    dispatch(TrainingActions.loadTrainingFailure("SETUP - Erro no Carregamento"))
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error);
                dispatch(TrainingActions.loadTrainingFailure(`SETUP - ${error.message}`))
            })
    },
    loadTheme: (data: any) => {
        const { TituloModulo, CorPrimaria, CorSecundaria } = JSON.parse(data)[0]
        asyncLocalStorage.setItem("@services_page_title", TituloModulo)
            .then(() => {
                simplifica.colors.primary = CorPrimaria
                simplifica.colors.secondary = CorSecundaria

                simplifica.trainingButtons[0].color = CorPrimaria
                simplifica.trainingButtons[1].color = CorPrimaria

                simplifica.classifiedCategories.tipoUso[0].background = CorSecundaria
            })
    },
    loadTrainings: () => (dispatch: Dispatch) => {
        api.get('/treinamentos/list')
            .then((response: any) => {
                if (response && response.data && response.data.state) {
                    dispatch(TrainingActions.loadTrainingSuccess(JSON.parse(response.data.data)))
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error);
                dispatch(TrainingActions.loadTrainingFailure(`${error.message}`))
            })
    },
    loadSingleTraining: (trainingid: number) => (dispatch: Dispatch) => {
        api.get(`/treinamentos/show/${trainingid}`)
            .then((response: any) => {
                if (response.data.state) {
                    const content = JSON.parse(response.data.data)[0]
                    TrainingThunk.loadTrainingHtml(content.conteudo)
                        .then(data => {
                            content.conteudo = data
                            dispatch(TrainingActions.loadSingleTrainingSucess(content))
                        })
                        .catch((error: AxiosError) => {
                            dispatch(TrainingActions.loadTrainingFailure(`${error.message}`))
                        })
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error);
                dispatch(TrainingActions.loadTrainingFailure(`${error.message}`))
            })
    },
    loadTrainingHtml: (trainingHtml: string) => {
        const url = trainingHtml.replace('/services', '')
        const content = new Promise((res, rej) => api.get(url)
            .then((response: any) => {
                if (response.data) res(response.data)
            })
            .catch((error: AxiosError) => {
                rej(error)
                Sentry.captureException(error);
            })
        )
        return content
    },
    completeTraining: (trainingid: number) => (dispatch: Dispatch) => {
        api.post('/treinamentos/complete', { codigo: trainingid })
            .then((response: any) => {
                if (response && response.data && response.data.state) {
                    TrainingThunk.loadTrainings()
                    dispatch(TrainingActions.completeTrainingSuccess(response.data.message))
                } else {
                    Sentry.captureException(response.data.message);
                    dispatch(TrainingActions.completeTrainingFailure(`${response.data.message}`))
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error);
                dispatch(TrainingActions.completeTrainingFailure(`${error.message}`))
            })
    },
}

export default TrainingThunk