import styled from 'styled-components'

export const BreadcrumbContainer = styled.div`
    position: relative;
    margin-bottom: 60px;
`

export const BreadcrumbWrapper = styled.div`
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 16px;
    background-color: ${(props) => props.theme.colors.background};
    z-index: 5;
    top: 0;
`

export const BreadcrumbPage = styled.h1`
    font-size: 20px;
    font-weight: 700;

    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
        display: block;
        font-size: 20px;
        font-weight: 800;

        color: ${(props) => props.theme.colors.primary};
    }
`
export const BreadcrumbBack = styled.button`
    font-size: 15px;
    font-weight: 600;

    background-color: transparent;
    border: none;

    display: flex;
    align-items: center;

    img {
        margin-right: 5px;
    }
`
