import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Redirect } from 'react-router'
import { ApplicationState } from '../../../store'
import { ITerm } from '../../../store/containers/terms/types'
import TermThunk from '../../../store/containers/terms/thunk'
import ThatsAll from '../../../components/ThatsAll'
import Item from './components/Item'
import TermBadge from './components/TermBadge'
import { Container, TermContainer, Title, Divider, TermHeader } from './style'
import TermCardSkeleton from './components/Item/termCardSkeleton'
import { i18n } from '../../../translate/i18n'

interface StateProps {
    terms: ITerm[]
    error: boolean
    loading: boolean
    errorMessage: string
}

interface DispatchProps {
    loadTerms(): void
}

type Props = StateProps & DispatchProps

const TermList: React.FC<Props> = (props: Props) => {
    const [todo, setTodo] = useState<ITerm[]>([])
    const [done, setDone] = useState<ITerm[]>([])

    useEffect(() => {
        const { loadTerms } = props
        loadTerms()
    }, [])

    const { terms, error, loading, errorMessage } = props

    useEffect(() => {
        setTodo(terms.filter((term) => term.status === 0))
        setDone(terms.filter((term) => term.status === 1))
    }, [terms])

    const groupSkeletonCards = useCallback((numCards: number) => {
        const skeletonCards: Array<JSX.Element> = []

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < numCards; i++) {
            skeletonCards.push(<TermCardSkeleton />)
        }

        return <>{skeletonCards.map((s) => s)}</>
    }, [])

    const todoTerms = () => (
        <>
            <TermHeader>
                <Title>{i18n.t('terms.pendings')}</Title>
                <TermBadge badgeValue={todo.length} />
            </TermHeader>

            <TermContainer>
                {todo.map((term: ITerm, key) => (
                    <Item key={key} term={term} />
                ))}
            </TermContainer>
        </>
    )

    const doneTerms = () => (
        <TermContainer>
            <Title>{i18n.t('terms.accepted')}</Title>
            {done.map((term: ITerm, key) => (
                <Item key={key} term={term} />
            ))}
        </TermContainer>
    )

    return (
        <>
            {!error ? (
                <>
                    <Divider />
                    <Container>
                        {loading ? (
                            groupSkeletonCards(5)
                        ) : (
                            <>
                                {todo.length > 0 && todoTerms()}

                                {done.length > 0 && doneTerms()}
                            </>
                        )}
                    </Container>
                </>
            ) : (
                <Redirect
                    to={{ pathname: '/error', state: { errorMessage } }}
                />
            )}
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    terms: state.terms.terms,
    loading: state.terms.loading,
    error: state.terms.error,
    errorMessage: state.terms.errorMessage,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadTerms: () => dispatch(TermThunk.loadTerms() as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(TermList)
