import { Reducer } from "redux";
import { ClassifiedState, ClassifiedTypes, IAnnouncement, IClassifiedSeller, IClassifiedSetup } from "./types";

const INITIAL_STATE: ClassifiedState = {
    setup: {} as IClassifiedSetup,
    products: [],
    services: [],
    seller: {} as IClassifiedSeller,
    myAnnouncements: [],
    actualAnnouncement: {} as IAnnouncement,
    categories: [],
    announcementTypes: [],
    terms: '',
    error: false,
    loading: true,
    errorMessage: ""
}

const reducer: Reducer<ClassifiedState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ClassifiedTypes.LOAD_CLASSIFIED_SETUP:
            return { ...state, loading: false, error: false, setup: action.payload.data };
        case ClassifiedTypes.LOAD_CLASSIFIED_REQUEST:
            return { ...state, loading: true }
        case ClassifiedTypes.LOAD_CLASSIFIED_PRODUCTS:
            return {
                ...state,
                loading: false,
                error: false,
                products: action.payload.data
            }
        case ClassifiedTypes.LOAD_CLASSIFIED_SERVICES:
            return {
                ...state,
                loading: false,
                error: false,
                services: action.payload.data
            }
        case ClassifiedTypes.LOAD_CLASSIFIED_MY_PRODUCTS:
            return {
                ...state,
                loading: false,
                error: false,
                myAnnouncements: action.payload.data
            }
        case ClassifiedTypes.LOAD_ANNOUNCEMENT:
            return {
                ...state,
                loading: false,
                error: false,
                actualAnnouncement: action.payload.data
            }
        case ClassifiedTypes.UNLOAD_ANNOUNCEMENT:
            return { ...state, loading: false, error: false, actualAnnouncement: {} }
        case ClassifiedTypes.LOAD_CLASSIFIED_FAILURE:
            return { ...state, loading: false, error: true, errorMessage: action.payload }
        case ClassifiedTypes.LOAD_SELLER_DATA:
            return { ...state, loading: false, error: false, seller: action.payload.data }
        case ClassifiedTypes.LOAD_CLASSIFIED_CATEGORIES:
            return { ...state, categories: action.payload.data }

        case ClassifiedTypes.LOAD_CLASSIFIED_ANNOUNCEMENT_TYPES:
            return { ...state, announcementTypes: action.payload.data }

        case ClassifiedTypes.SET_CLASSIFIED_TERMS:
            return { ...state, terms: action.payload.data }
        default:
            return state
    }
}

export default reducer