import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
