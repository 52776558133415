import styled from 'styled-components';

export const Container = styled.div<{name: string}>`
    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
`

export const Root = styled.div`
  margin: 5px;
  cursor: pointer;
  position: relative;

  label {
      display: flex;
      margin-left: 20px;
      font-size: 18px;
      color: ${props => props.theme.colors.text}
  }

  &::before {
      content: "";
      border-radius: 100%;
      border: 1px solid #DDD;
      background-color: #FAFAFA;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      box-sizing: border-box;
      pointer-events: none;
      z-index: 0;
      box-shadow: inset 0px 3px 2px rgba(0, 0, 0, 0.22);
  }
`;

export const Fill = styled.div<{ isActive?: boolean }>`
    background-color: ${props => props.theme.colors.primary};
    width: 0;
    height: 0;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(-50%, -50%);
    transition: none 0.2s ease-in, height 0.2s ease-in;
    pointer-events: none;
    z-index: 1;
`

export const Input = styled.input`
    opacity: 0;
    z-index: 2;
    position: absolute;
    top: 0;
    width: 80%;
    height: 100%;
    margin: 0;
    cursor: pointer;

    &::focus {
        outline: none;
    }

    &:checked {
        & ~ ${Fill} {
            width: 12px;
            height: 12px;
            transition: width 0.2s ease-out, height 0.2s ease-out;

            &::before {
                opacity: 1;
                transition: opacity 1s ease;
            }
        }
  }
`
