import React from 'react';

import { Container, Dot, Text, Headline } from './style';

type Props = {
    children: string
}

const ThatsAll: React.FC<Props> = ({ children }: Props) => (
    <Container>
        <Dot size={3} />
        <Dot size={5} />
        <Dot size={7} />
        <Headline>Isso é tudo!</Headline>
        <Text>
            {children}
        </Text>
    </Container>
)

export default ThatsAll;