import { Reducer } from "redux";
import { IUser, UserState, UserTypes } from "./types";

const INITIAL_STATE: UserState = {
    user: {} as IUser,
    loading: false,
    error: false
}

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserTypes.LOAD_USER_REQUEST:
            return { ...state, loading: true }
        case UserTypes.LOAD_USER_SUCCESS:
            return { ...state, loading: false, error: false, user: action.payload.data }
        case UserTypes.LOAD_USER_FAILURE:
            return { ...state, loading: false, error: true }
        default:
            return state
    }
}

export default reducer