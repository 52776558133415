import React, { useCallback, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import Switch from 'react-switch'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useConfirmation } from '../../../../../components/Modal/Confirmation/confirmation-service'
import { useForm } from '../../../../../hooks/useForm'
import { ApplicationState } from '../../../../../store'
import {
    IAnnouncement,
    IClassifiedCategories,
    IClassifiedSetup,
    IClassifiedTypeAnnouncement,
} from '../../../../../store/containers/classified/types'
import ClassifiedThunk from '../../../../../store/containers/classified/thunk'
import { Radio, RadioGroup } from '../../../../../components/Forms/RadioGroup'
import { FileGroup, ImageFile } from '../../../../../components/Forms/FileGroup'

import {
    Form,
    FormGroup,
    Option,
    Select,
    Currency,
    Input,
    ValidateInput,
    TermsTitle,
    Terms,
    Divider,
    AproveNotification,
    Submit,
    Label,
    FileInfo,
} from './style'

import publish from '../../../../../assets/images/publish.svg'
import AppThunk from '../../../../../store/containers/app/thunk'
import Loader from '../../../../../components/Loader'

interface newAnnouncement {
    TipoAnuncio: string
    TipoUso: string
    Valor: string
    AceitaTroca: string
    Categoria: string
    Imagem1: any
    Imagem2: any
    Imagem3: any
    Titulo: string
    Descricao: string
    EmailContato: string
    TelefoneContato: string
    TermoAceito: string
}

interface StateProps {
    categories: IClassifiedCategories[]
    setup: IClassifiedSetup
    terms: string
    announcementTypes: IClassifiedTypeAnnouncement[]
    uploadLoading: boolean
    uploadError: boolean
}

interface DispatchProps {
    loadCategories: () => void
    storeAnnouncement: (data: any, imageData: any, id?: number) => void
    loadTerms: () => any
    loadAnnouncementTypes: () => void
    nativeUploadFile: (filename: string, route: string) => any
}

type Props = {
    announcement: IAnnouncement | null
} & StateProps &
    DispatchProps

const AnnouncementForm: React.FC<Props> = (props: Props) => {
    const [checkTerms, setCheckTerms] = useState(false)
    const [image0, setImage0] = useState<string>('')
    const [image1, setImage1] = useState<string>('')
    const [image2, setImage2] = useState<string>('')

    const confirm = useConfirmation()
    const history = useHistory()

    const {
        announcement,
        setup,
        categories,
        terms,
        announcementTypes,
        uploadLoading,
        uploadError,
        nativeUploadFile,
    } = props

    useEffect(() => {
        const { loadCategories, loadTerms, loadAnnouncementTypes } = props
        loadCategories()
        loadTerms()
        loadAnnouncementTypes()
    }, [])

    const onUpload = useCallback(
        (filename: string) => {
            nativeUploadFile(filename, '/classificados/uploadimage')
        },
        [announcement]
    )

    useEffect(() => {
        if (announcement?.Imagem1) {
            setImage0(announcement?.Imagem1)
        }
        if (announcement?.Imagem2) {
            setImage1(announcement?.Imagem2)
        }
        if (announcement?.Imagem3) {
            setImage2(announcement?.Imagem3)
        }
        if (announcement?.TermoAceito) {
            setCheckTerms(Boolean(announcement?.TermoAceito))
        }
    }, [announcement])

    const { id } = useParams<{ id: string }>()

    const { handleSubmit, handleChange, data, errors, setData } =
        useForm<newAnnouncement>({
            validations: {
                TipoAnuncio: {
                    required: {
                        value: true,
                        message: 'O Campo Tipo é obrigatorio',
                    },
                },
                Titulo: {
                    required: {
                        value: true,
                        message: 'O Campo Título é obrigatorio',
                    },
                },
                Valor: {
                    required: {
                        value: true,
                        message: 'O Campo Valor é obrigatorio',
                    },
                    pattern: {
                        value: '[^0-9^]',
                        message: 'O Campo Valor aceita somente números',
                    },
                },
                EmailContato: {
                    required: {
                        value: true,
                        message: 'O Campo Email é obrigatorio',
                    },
                    custom: {
                        isValid: (value) => {
                            if (
                                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                    value
                                )
                            ) {
                                return true
                            }
                            return false
                        },
                        message: 'Insira um email válido',
                    },
                },
                TelefoneContato: {
                    required: {
                        value: true,
                        message: 'O Campo Telefone é obrigatorio ',
                    },
                },
            },

            onSubmit: (e) => {
                const { storeAnnouncement } = props

                if (!checkTerms) {
                    confirm({
                        variant: 'info',
                        catchOnCancel: false,
                        description:
                            'Aceite os termos para cadastrar seu anuncio',
                    })
                    return
                }

                const formData = new FormData(e.target as HTMLFormElement)
                const imageData: Array<FormData> = []

                const imageFiles: Array<any> = [
                    formData.get('Imagem1'),
                    formData.get('Imagem2'),
                    formData.get('Imagem3'),
                ]

                const allocateToTheTopImage = (
                    index: number,
                    imageHash: any
                ) => {
                    if (
                        !imageData[index - 1] &&
                        !formData.get(`Imagem${index}`) &&
                        index > 0
                    ) {
                        allocateToTheTopImage(index - 1, imageHash)
                    } else {
                        imageData[index] = new FormData()
                        imageData[index].append(`Imagem${index + 1}`, imageHash)
                    }
                }

                const allocateToTheTopUrl = (
                    index: number,
                    imageURL: string
                ) => {
                    if (
                        !formData.get(`Imagem${index}`) &&
                        !imageData[index - 1] &&
                        index > 0
                    ) {
                        allocateToTheTopUrl(index - 1, imageURL)
                    } else if (imageURL.includes('http')) {
                        formData.append(`Imagem${index + 1}`, imageURL)
                    }
                }

                let numImages = 0
                const arrImages = [image0, image1, image2]

                imageFiles.forEach((image, index) => {
                    formData.delete(`Imagem${index + 1}`)
                })

                imageFiles.forEach((image: File, index) => {
                    if (image.size > 0) {
                        allocateToTheTopImage(index, imageFiles[index])
                        numImages += 1
                    }

                    if (id) {
                        if (arrImages[index]) {
                            allocateToTheTopUrl(index, arrImages[index])
                        } else {
                            arrImages.splice(index, 0)
                        }
                    }
                })

                if (numImages === 0 && !id) {
                    confirm({
                        variant: 'info',
                        catchOnCancel: false,
                        description: 'Insira ao menos uma imagem ao anúncio',
                    })
                    return
                }

                if (formData.get('TermoAceito') === 'true') {
                    formData.set('TermoAceito', '1')
                } else {
                    formData.set('TermoAceito', '0')
                }

                const clearAnnouncementForm = () => {
                    setImage0('')
                    setImage1('')
                    setImage2('')
                }

                confirm({
                    variant: 'danger',
                    icon: publish,
                    catchOnCancel: true,
                    description:
                        setup.RequerAprovacaoProduto ||
                        setup.RequerAprovacaoServico
                            ? 'Esta ação não poderá ser desfeita e está sujeita a aprovação.'
                            : 'Seu anúncio foi enviado com sucesso!',
                }).then(() => {
                    storeAnnouncement(
                        Object.fromEntries(formData),
                        imageData,
                        announcement?.CodigoClassificadoItem &&
                            announcement?.CodigoClassificadoItem
                    )
                    clearAnnouncementForm()
                    history.goBack()
                })
            },
            initialValues: {
                TipoAnuncio: String(announcement?.TipoAnuncio) || '',
                TipoUso: announcement?.TipoUso
                    ? String(announcement?.TipoUso)
                    : '0',
                Categoria: String(announcement?.Categoria) || '',
                Valor: announcement?.Valor || '',
                AceitaTroca: announcement?.AceitaTroca
                    ? String(announcement?.AceitaTroca)
                    : 'true',
                Titulo: announcement?.Titulo || '',
                Descricao: announcement?.Descricao || '',
                EmailContato: announcement?.EmailContato || '',
                TelefoneContato: announcement?.TelefoneContato || '',
                Imagem1: announcement?.Imagem1 || '',
                Imagem2: announcement?.Imagem2 || '',
                Imagem3: announcement?.Imagem3 || '',
            },
        })

    const ValorMask = (aCost: string) => {
        if (aCost.length > 9) return
        let formatedCost = aCost.replace(/\D/g, '')
        formatedCost = formatedCost.replace(/(\d)(\d{2})$/, '$1,$2')

        setData({ ...data, Valor: formatedCost })
    }

    const TelefoneMask = (tel: string) => {
        if (tel.length > 15) return

        let formatedCost = tel.replace(/\D/g, '')
        if (tel.length > 1 && tel.length < 11) {
            formatedCost = formatedCost.replace(/(\d{2})(\d+)$/, '($1) $2')
        } else if (tel.length >= 11) {
            formatedCost = formatedCost.replace(
                /(\d{2})(\d{5})(\d+)$/,
                '($1) $2-$3'
            )
        }

        setData({ ...data, TelefoneContato: formatedCost })
    }

    const { colors } = useTheme()

    return (
        <Loader
            loading={uploadLoading}
            error={uploadError}
            loadMessage='Carregando Imagem...'>
            <Form encType='multipart/form-data' onSubmit={handleSubmit}>
                <FormGroup>
                    <Select
                        name='TipoAnuncio'
                        onChange={handleChange('TipoAnuncio')}
                        value={data.TipoAnuncio}
                        error={errors.TipoAnuncio}
                        required>
                        <Option value=''> Selecionar tipo do anúncio </Option>
                        {announcementTypes &&
                            announcementTypes.length > 0 &&
                            announcementTypes.map((allowedType) => (
                                <Option
                                    key={allowedType.id}
                                    value={allowedType.id}>
                                    {allowedType.descricao}
                                </Option>
                            ))}
                    </Select>
                    {errors.TipoAnuncio && (
                        <ValidateInput>{errors.TipoAnuncio}</ValidateInput>
                    )}
                </FormGroup>
                <FormGroup>
                    <RadioGroup
                        name='TipoUso'
                        onClickRadioButton={handleChange('TipoUso')}
                        selectedValue={data.TipoUso}>
                        <Radio labelText='Novo' value='1' />
                        <Radio labelText='Usado' value='0' />
                    </RadioGroup>
                </FormGroup>
                <FormGroup>
                    <Select
                        name='Categoria'
                        onChange={handleChange('Categoria')}
                        value={data.Categoria}
                        error={errors.Categoria}
                        required>
                        <Option value=''>Selecione uma Categoria</Option>
                        {categories &&
                            categories.length > 0 &&
                            categories.map((category) => (
                                <Option key={category.id} value={category.id}>
                                    {category.descricao}
                                </Option>
                            ))}
                    </Select>
                    {errors.Categoria && (
                        <ValidateInput>{errors.Categoria}</ValidateInput>
                    )}
                </FormGroup>
                <FormGroup>
                    <Input
                        type='text'
                        name='Titulo'
                        placeholder='Título do anúncio'
                        onChange={handleChange('Titulo')}
                        error={errors.Titulo}
                        value={data.Titulo}
                    />
                    {errors.Titulo && (
                        <ValidateInput>{errors.Titulo}</ValidateInput>
                    )}
                </FormGroup>
                <FormGroup margin='30px 0'>
                    <FormGroup flex>
                        <FileGroup onUpload={onUpload}>
                            <input type='file' name='Imagem1' />
                            <input type='file' name='Imagem2' />
                            {/* <ImageFile
                                imageIndex={1}
                                image={image0}
                                setImage={setImage0}
                            />
                            <ImageFile
                                imageIndex={2}
                                image={image1}
                                setImage={setImage1}
                            />
                            <ImageFile
                                imageIndex={3}
                                image={image2}
                                setImage={setImage2}
                            /> */}
                        </FileGroup>
                    </FormGroup>
                    <FileInfo>
                        Clique para adicionar imagem
                        <span>( imagem obrigatória )</span>
                    </FileInfo>
                </FormGroup>
                <FormGroup>
                    <Input
                        type='text'
                        name='Descricao'
                        placeholder='Descrição'
                        value={data.Descricao}
                        onChange={handleChange('Descricao')}
                        error={errors.Descricao}
                    />
                    {errors.Descricao && (
                        <ValidateInput>{errors.Descricao}</ValidateInput>
                    )}
                </FormGroup>
                <FormGroup>
                    <Currency>R$</Currency>
                    <Input
                        style={{ paddingRight: '40px', textAlign: 'right' }}
                        type='text'
                        name='Valor'
                        placeholder='Valor R$'
                        value={data.Valor}
                        onChange={(event) => ValorMask(event.target.value)}
                        error={errors.Valor}
                    />
                    {errors.Valor && (
                        <ValidateInput>{errors.Valor}</ValidateInput>
                    )}
                </FormGroup>
                <FormGroup flex>
                    <Label>Aceita troca</Label>
                    <RadioGroup
                        name='AceitaTroca'
                        onClickRadioButton={handleChange('AceitaTroca')}
                        selectedValue={data.AceitaTroca}>
                        <Radio labelText='Sim' value='true' />
                        <Radio labelText='Não' value='false' />
                    </RadioGroup>
                </FormGroup>
                <FormGroup>
                    <Input
                        type='email'
                        name='EmailContato'
                        placeholder='E-mail de contato'
                        value={data.EmailContato}
                        onChange={handleChange('EmailContato')}
                        error={errors.EmailContato}
                        required
                    />
                    {errors.EmailContato && (
                        <ValidateInput>{errors.EmailContato}</ValidateInput>
                    )}
                </FormGroup>
                <FormGroup>
                    <Input
                        type='tel'
                        name='TelefoneContato'
                        placeholder='Telefone para contato'
                        value={data.TelefoneContato}
                        onChange={(event) => TelefoneMask(event.target.value)}
                        error={errors.TelefoneContato}
                        required
                    />
                    {errors.TelefoneContato && (
                        <ValidateInput>{errors.TelefoneContato}</ValidateInput>
                    )}
                </FormGroup>
                <FormGroup>
                    <TermsTitle>Termos de uso</TermsTitle>
                    <Terms>
                        <input
                            type='checkbox'
                            name='TermoAceito'
                            checked={checkTerms}
                            onChange={handleChange('TermoAceito')}
                            value={String(checkTerms)}
                        />
                        <embed src={terms} />
                    </Terms>
                </FormGroup>
                <FormGroup>
                    <Switch
                        checked={checkTerms}
                        onChange={setCheckTerms}
                        onColor={colors.primary}
                    />
                </FormGroup>
                <Divider />
                <FormGroup flex>
                    <AproveNotification>
                        {data.TipoAnuncio === '0' &&
                            setup.RequerAprovacaoProduto &&
                            'Publicações estão sujeitas à aprovação'}
                        {data.TipoAnuncio === '1' &&
                            setup.RequerAprovacaoServico &&
                            'Serviços estão sujeitos à aprovação'}
                    </AproveNotification>

                    <Submit type='submit'>
                        <img src={publish} alt='' />
                        Publicar
                    </Submit>
                </FormGroup>
            </Form>
        </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    categories: state.classifieds.categories,
    setup: state.classifieds.setup,
    terms: state.classifieds.terms,
    announcementTypes: state.classifieds.announcementTypes,
    uploadLoading: state.app.uploadLoading,
    uploadError: state.app.uploadError,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadCategories: () => dispatch(ClassifiedThunk.loadCategories() as any),
    loadTerms: () => dispatch(ClassifiedThunk.loadTerms() as any),
    loadAnnouncementTypes: () =>
        dispatch(ClassifiedThunk.loadAnnouncementTypes() as any),
    storeAnnouncement: (data: any, imageData: any, id?: number) =>
        dispatch(ClassifiedThunk.storeAnnouncement(data, imageData, id) as any),
    nativeUploadFile: (filename: string, route: string) =>
        dispatch(AppThunk.nativeUploadFile(filename, route) as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementForm)
