import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup } from '../../../../../components/Forms/RadioGroup';

import { Container, Divider } from './style';

type Props = {
    usedProducts: number,
    newProducts: number,
    setTipoUso: (index: number) => void
}

const TypeProducts: React.FC<Props> = ({ usedProducts, newProducts, setTipoUso }: Props) => {

    const [selectedValue, setSelectedValue] = useState("1");

    const handleInputChange = (event:React.ChangeEvent<any>) => {
        setSelectedValue(event.currentTarget.value);
    }

    useEffect(() => {
        setTipoUso(Number(selectedValue))
    }, [selectedValue])

    return (
        <Container>
            <RadioGroup
                name="typeProducts"
                onClickRadioButton={handleInputChange}
                selectedValue={selectedValue}
            >
                <Radio value='0' labelText={`${usedProducts} Usados`} />
                <Divider />
                <Radio value='1' labelText={`${newProducts} Novos`} />
            </RadioGroup>
        </Container>
    )
}

export default TypeProducts;