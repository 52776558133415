import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import { Dispatch } from 'redux'
import { ApplicationState } from '../../store'
import DocumentsThunk from '../../store/containers/documents/thunk'
import Loader from '../../components/Loader'

interface StateProps {
    loading: boolean
    error: boolean
    errorMessage: string
}

interface DispatchProps {
    loadSetup: (hash: string) => void
}

type Props = StateProps & DispatchProps

const Documents: React.FC<Props> = (props: Props) => {
    const { hash } = useLocation()

    useEffect(() => {
        const { loadSetup } = props
        loadSetup(hash)
    }, [])

    const { loading, error, errorMessage } = props

    return (
        <Loader loading={loading} error={error} errorMessage={errorMessage}>
            <Redirect to={{ pathname: '/docsrh/list' }} />
        </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.documents.loading,
    error: state.documents.error,
    errorMessage: state.documents.errorMessage,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadSetup: (hash: string) =>
        dispatch(DocumentsThunk.loadSetup(hash) as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(Documents)
