import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
`

export const ContainerList = styled.div`
    position: relative;
    margin: 18px;
`
export const Card = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.card.background};
    color: ${(props) => props.theme.card.text};
    padding: 20px;
    margin-bottom: 15px;

    border-radius: ${(props) => props.theme.card.borderRadius};
    border: 1px solid ${(props) => props.theme.colors.primary};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);

    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: stretch;
`

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const CardImage = styled.div`
    position: relative;
    width: 115px;
    overflow: hidden;

    img {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 115px;
    }
`
export const CardCategory = styled.p`
    font-size: 11px;
    font-weight: 400;
`
export const CardTitle = styled.h1`
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
`

export const CardWrapper = styled.div`
    position: relative;
`

export const CardValor = styled.p`
    font-size: 18px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 8px;
`

export const CardButton = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    padding: 4px 8px;
    border-radius: 5px;

    width: fit-content;
    display: flex;

    span,
    a {
        font-size: 12px;
        font-weight: 700;
        color: white;
    }
`

export const AnnouncementOptions = styled.div`
    display: flex;
    gap: 15px;
    justify-content: start;
    align-items: center;
    margin-top: 15px;

    div:nth-child(n) {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`

export const AnnouncementEdit = styled.div`
    > button {
        background-color: transparent;
        color: ${(props) => props.theme.colors.text};
        font-size: 15px;
        font-weight: 700;
        line-height: 100%;
        border: none;
    }
`

export const AnnouncementComments = styled.div`
    > span {
        color: ${(props) => props.theme.colors.text};
        font-size: 15px;
        font-weight: 700;
        line-height: 100%;
    }
`

export const AnnouncementStatus = styled.div``

export const CreateButton = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    border: none;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    margin: 0 20px 20px;

    position: relative;

    bottom: 0;

    a {
        font-size: 18px;
        font-weight: 700;
        color: white;
    }
`

export const EditOptions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    > a,
    > button {
        color: ${(props) => props.theme.colors.primary};
        font-size: 20px;
        font-weight: 400;
        line-height: 100%;
        border: none;
        background-color: transparent;
        padding: 15px;
        text-align: left;
        border-radius: 10px;
    }
`
