/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { useParams } from 'react-router'
import { ApplicationState } from '../../../store'
import ClassifiedThunk from '../../../store/containers/classified/thunk'
import { IAnnouncement } from '../../../store/containers/classified/types'

import Breadcrumb from '../../../components/Breadcrumb'

import Loader from '../../../components/Loader'
import AnnouncementForm from './components/AnnouncementForm'
import { Container } from './style'

interface StateProps {
    loading: boolean
    error: boolean
    announcement: IAnnouncement
}

interface DispatchProps {
    loadRequest: () => void
    loadAnnouncement: (id: number) => void
}

type Props = StateProps & DispatchProps

const ClassifiedCreate: React.FC<Props> = (props: Props) => {
    const { id } = useParams<{ id: string }>()

    useEffect(() => {
        if (id) {
            const { loadRequest, loadAnnouncement } = props

            loadRequest()
            loadAnnouncement(Number(id))
        }
    }, [])

    useEffect(() => {
        document.body.style.overflow = 'unset'
    }, [])

    const { announcement, loading, error } = props

    return (
        <>
            <Breadcrumb>
                Meus Anúncios <span>Novo Anúncio</span>
            </Breadcrumb>
            <Loader
                loading={loading}
                error={error}
                show={Object.keys(announcement).length <= 0 && Boolean(id)}>
                <Container>
                    <AnnouncementForm
                        announcement={id ? announcement : ({} as IAnnouncement)}
                    />
                </Container>
            </Loader>
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.classifieds.loading,
    error: state.classifieds.error,
    categories: state.classifieds.categories,
    setup: state.classifieds.setup,
    terms: state.classifieds.terms,
    announcement: state.classifieds.actualAnnouncement,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadRequest: () =>
        dispatch({ type: '@classifieds/LOAD_CLASSIFIED_REQUEST' }),
    storeAnnouncement: (data: any, imageData: Array<FormData>) =>
        dispatch(ClassifiedThunk.storeAnnouncement(data, imageData) as any),
    loadAnnouncement: (id: number) =>
        dispatch(ClassifiedThunk.loadAnnouncement(id) as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassifiedCreate)
