import styled from 'styled-components'

export const CardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.background};
    width: 100%;
    padding: 8px 16px;
    margin-top: 16px;
    gap: 8px;
    word-wrap: break-word;
    overflow: hidden;
`

export const UserPhoto = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
`
export const UserPhotoPlaceholder = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ededed;
`

export const UserWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    flex: 1;
    word-wrap: break-word;
    overflow: hidden;
`

export const UserInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
`

export const Day = styled.p`
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
`

export const ColNome = styled.p`
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.text};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: break-word;
`

export const ColCargo = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.colors.textMuted};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
`

export const DateWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 70px;
    gap: 2px;
`

export const Since = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.colors.textMuted};
`

export const YearContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`

export const YearsInWork = styled.p`
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.text};
`

export const Years = styled.span`
    font-size: 24px;
    color: ${(props) => props.theme.colors.text};
    font-weight: 800;
`
