import React from 'react';
import { useTheme } from 'styled-components';
import { StatusLabel } from './style';

type Props = {
    status: number,
    statusName: string,
}
const MyAnnouncementsStatus: React.FC<Props> = (props: Props) => {
    const { status, statusName } = props
    const { colors } = useTheme()

    const listStatus = [
        { key: 0, color: colors.info },
        { key: 1, color: colors.success },
        { key: 3, color: colors.danger },
        { key: 4, color: colors.success },
        { key: 5, color: colors.danger },
    ]

    return (
        <StatusLabel color={listStatus.find(res => res.key === status)?.color}>
            <span>{statusName}</span>
        </StatusLabel>
    )
}
export default MyAnnouncementsStatus;