/* eslint-disable react/no-danger */
import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Redirect, useHistory, useParams } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import { useConfirmation } from '../../../components/Modal/Confirmation/confirmation-service';
import { ApplicationState } from '../../../store';
import TrainingThunk from '../../../store/containers/training/thunk';
import { ITraining } from '../../../store/containers/training/types';
import { Container, Header, Image, HeaderContainer, Title, Department, Button, Divider } from './style';
import defaulTrainingImage from "../../../assets/images/default.jpg"
import TrainingContent from './components/TrainingContent';

interface IParams {
    id: string
}

interface StateProps {
    activeTraining: ITraining
    loading: boolean,
    error: boolean,
    errorMessage: string
}

interface DispatchProps {
    loadTrainingRequest(): void
    loadSingleTraining(id: number): ITraining
    completeTraining(trainingid: number): void
}

type Props = StateProps & DispatchProps

const TrainingDetail: React.FC<Props> = (props: Props) => {

    const { id } = useParams<IParams>()
    const confirm = useConfirmation()
    const history = useHistory()

    useEffect(() => {
        const { loadSingleTraining, loadTrainingRequest } = props

        loadTrainingRequest()
        loadSingleTraining(Number(id))
    }, [id])

    function imageError(ev: SyntheticEvent<HTMLImageElement, Event>) {
        // eslint-disable-next-line no-param-reassign
        ev.currentTarget.src = defaulTrainingImage
    }

    const handleCompleteTraining = useCallback(() => {
        const { completeTraining } = props

        confirm({
            variant: 'info',
            description: "Deseja concluir este treinamento?"
        })
            .then(() => {
                completeTraining(Number(id))
                confirm({
                    variant: 'info',
                    description: 'Treinamento concluido!'
                })
                    .then(() => {
                        history.push('/training/list')
                    })
            })
    }, [])


    const { activeTraining, loading, error, errorMessage } = props

    return (
        <>
            {!error ?
                <>
                    <Divider />
                    <Container>
                        <Header>
                            {!loading ?
                                <Image onError={imageError} src={activeTraining.imagem} />
                                :
                                <Skeleton circle width={68} height={68} />
                            }
                            <HeaderContainer>
                                <Title>{!loading ? activeTraining.titulo : <Skeleton width={200} height={29} />}</Title>
                                <Department>{!loading ? activeTraining.subtitulo : <Skeleton height={18} width={80} />}</Department>
                            </HeaderContainer>
                        </Header>

                        {!loading ?
                            <TrainingContent text={activeTraining.conteudo} />
                            :
                            <div style={{ margin: '20px 0' }}>
                                <Skeleton count={25} />
                            </div>
                        }

                        {activeTraining.status === 0 && <Button primary onClick={handleCompleteTraining} >Concluir</Button>}
                    </Container>
                </>
                :
                <Redirect to={{ pathname: '/error', state: { erroMessage: errorMessage } }} />
            }
        </>
    );
}

const mapStatesToProps = (state: ApplicationState) => ({
    loading: state.trainings.loading,
    error: state.trainings.error,
    activeTraining: state.trainings.activeTraining,
    errorMessage: state.trainings.errorMessage
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadTrainingRequest: () => dispatch({ type: "@training/LOAD_TRAINING_REQUEST" }),
    loadSingleTraining: (id: number) => dispatch(TrainingThunk.loadSingleTraining(id) as any),
    completeTraining: (trainingid: number) => dispatch(TrainingThunk.completeTraining(trainingid) as any)
})

export default connect(mapStatesToProps, mapDispatchToProps)(TrainingDetail);