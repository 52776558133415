import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import TermsThunk from '../../store/containers/terms/thunk';
import TermList from './list'
import Loader from '../../components/Loader';

interface StateProps {
    loading: boolean,
    error: boolean,
    errorMessage: string
}

interface DispatchProps {
    loadSetup(hash: string): void
}

type Props = StateProps & DispatchProps

const Terms: React.FC<Props> = (props: Props) => {
    const { hash } = useLocation()

    const { loading, error, errorMessage } = props

    useEffect(() => {
        const { loadSetup } = props

        loadSetup(hash)
    }, [])

    return (
        <Loader loading={loading} error={error} errorMessage={errorMessage}>
            <Redirect to={{ pathname: "/terms/list" }} />
        </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.terms.loading,
    error: state.terms.error,
    errorMessage: state.terms.errorMessage
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadSetup: (hash: string) => dispatch(TermsThunk.loadSetup(hash) as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(Terms)