import React from 'react';

import { Container, Value } from './style';

type Props = {
    badgeValue: number
}

const TermBadge: React.FC<Props> = ({ badgeValue }: Props) => (
    <Container>
        <Value>{badgeValue}</Value>
    </Container>
)


export default TermBadge;