import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { asyncLocalStorage } from '../../../auth/auth'
import { api } from '../../../services/api'
import simplifica from '../../../styles/themes/simplifica'
import * as TermActions from './actions'

const TermThunk = {
    loadSetup: (hash: string) => (dispatch: Dispatch) => {
        dispatch(TermActions.loadTermRequest())
        api.post('/termos/setup', { hash })
            .then((response: AxiosResponse) => {
                if (response.data?.state) {
                    TermThunk.loadTheme(response.data.data)
                    asyncLocalStorage
                        .setItem(
                            '@services_header',
                            JSON.parse(response.data.data)[0].EsconderHeader
                        )
                        .then(() => dispatch(TermActions.loadTermsSetup()))
                } else {
                    Sentry.captureException('SETUP - Erro no Carregamento')
                    dispatch(
                        TermActions.loadTermFailure(
                            'SETUP - Erro no Carregamento'
                        )
                    )
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error)
                dispatch(
                    TermActions.loadTermFailure(`SETUP - ${error.message}`)
                )
            })
    },
    loadTheme: (data: any) => {
        const { TituloModulo, CorPrimaria, CorSecundaria } = JSON.parse(data)[0]
        asyncLocalStorage
            .setItem('@services_page_title', TituloModulo)
            .then(() => {
                simplifica.colors.primary = CorPrimaria
                simplifica.colors.secondary = CorSecundaria

                simplifica.trainingButtons[0].color = CorPrimaria
                simplifica.trainingButtons[1].color = CorPrimaria

                simplifica.classifiedCategories.tipoUso[0].background =
                    CorSecundaria
            })
    },
    loadTerms: () => (dispatch: Dispatch) => {
        api.get('/termos/list')
            .then((response: any) => {
                if (response.data.state) {
                    dispatch(
                        TermActions.loadTermSuccess(
                            JSON.parse(response.data.data)
                        )
                    )
                } else {
                    dispatch(
                        TermActions.loadTermFailure(
                            JSON.parse(response.data.message)
                        )
                    )
                }
            })
            .catch((err: any) => {
                dispatch(TermActions.loadTermFailure(err))
            })
    },

    loadSingleTerm: (termid: number) => (dispatch: Dispatch) => {
        api.get(`/termos/show/${termid}`)
            .then((response: any) => {
                if (response.data.state) {
                    const content = JSON.parse(response.data.data)[0]
                    TermThunk.loadTermHtml(content.conteudo)
                        .then((data) => {
                            content.conteudo = data
                            dispatch(TermActions.loadSingleTermSuccess(content))
                        })
                        .catch((error: AxiosError) => {
                            dispatch(
                                TermActions.loadTermFailure(
                                    JSON.parse(error.message)
                                )
                            )
                        })
                } else {
                    dispatch(
                        TermActions.loadTermFailure(
                            JSON.parse(response.data.message)
                        )
                    )
                }
            })
            .catch((error: AxiosError) => {
                dispatch(TermActions.loadTermFailure(error))
                Sentry.captureException(error)
            })
    },

    loadTermHtml: (termHtml: string) =>
        api
            .get(`/termos/content/${termHtml}`)
            .then((response: any) => response.data),

    completeTerm:
        (termid: number, signature?: string) => (dispatch: Dispatch) => {
            api.post('/termos/complete', {
                codigo: termid,
                assinatura: signature,
            })
                .then((response: any) => {
                    if (response.data.state) {
                        TermThunk.loadTerms()(dispatch)
                        dispatch(
                            TermActions.completeTermSuccess(
                                response.data.message
                            )
                        )
                    } else {
                        dispatch(
                            TermActions.completeTermFailure(
                                response.data.message
                            )
                        )
                    }
                })
                .catch((error: AxiosError) => {
                    Sentry.captureException(error)
                })
        },
}

export default TermThunk
