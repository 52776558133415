import styled from "styled-components";

export const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
`;

export const SwitchCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 26px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: -1px;
    bottom: -1px;
    background-color: #999;
    transition: 0.4s;
    border-radius: 50%;
  }

  ${SwitchCheckbox}:checked + & {
    background-color: ${({ theme }) => theme.colors.secondary}66;
  }

  ${SwitchCheckbox}:checked + &:before {
    background-color: ${({ theme }) => theme.colors.secondary};
    transform: translateX(26px);
  }
`;
