import { action } from "typesafe-actions";
import { ITrainingProgress, TrainingTypes } from "./types";

export const loadTrainingSetup = () => action(TrainingTypes.LOAD_TRAINING_SETUP)

export const loadTrainingRequest = () => action(TrainingTypes.LOAD_TRAINING_REQUEST)
export const loadTrainingSuccess = (data: any) => action(TrainingTypes.LOAD_TRAINING_SUCCESS, { data })
export const loadTrainingFailure = (error: any) => action(TrainingTypes.LOAD_TRAINING_FAILURE, error)

export const loadTrainingProgress = (progress: ITrainingProgress) => action(TrainingTypes.LOAD_TRAINING_PROGRESS, { progress })

export const loadSingleTrainingSucess = (data: any) => action(TrainingTypes.LOAD_SINGLE_TRAINING_SUCCESS, { data })

export const completeTrainingRequest = () => action(TrainingTypes.COMPLETE_TRAINING_REQUEST)
export const completeTrainingSuccess = (data: any) => action(TrainingTypes.COMPLETE_TRAINING_SUCCESS, { data })
export const completeTrainingFailure = (error: any) => action(TrainingTypes.COMPLETE_TRAINING_FAILURE, { error })